app.controller("CourseEmptyTrashController", function ($scope, $ngModal, MyCourseFactory, CategoryFactory) {

	$scope.isRemoving = false;

	$scope.$on("EMPTY_TRASH_COURSE", function (e, params) {
		$scope.callback = params.callback;
		$ngModal.openModal("course-empty-trash-modal");
	});

	function close () {
		$scope.isRemoving = false;
		$ngModal.closeModal("course-empty-trash-modal");
	};

	$scope.remove = function () {
		$scope.isRemoving = true;
		MyCourseFactory.emptyTrash().then(function (data) {
			$scope.callback(data.data);
			close();
		});
	};
});
