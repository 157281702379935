app.controller("CloneProjectController", function ($scope, $ngModal, MyProjectFactory, CategoryFactory) {

	$scope.isCloning = false;

	$scope.$on("CLONE_PROJECT", function (e, params) {
		$scope.callback = params.callback;
		$scope.project = params.project;
		$scope.newName = $scope.project.name;
		$scope.newDescription = $scope.project.description;
		$scope.folderId = params.folderId;
		$ngModal.openModal("clone-project-modal");
	});

	function close () {
		$scope.project = undefined;
		$scope.newName = undefined;
		$scope.newDescription = undefined;
		$scope.isCloning = false;
		$ngModal.closeModal("clone-project-modal");
	};

	$scope.clone = function () {
		$scope.isCloning = true;
		MyProjectFactory.clone($scope.project, $scope.newName, $scope.newDescription, $scope.folderId).then(function (data) {
			if (data.data) {
				$scope.callback(data.data);
				close();
			}
		});
	};
});