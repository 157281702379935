app.controller("AddProjectToCourseController", function($scope, $ngModal, $timeout, MyProjectFactory, CategoryFactory, ProjectFactory, FolderFactory) {

    if (!localStorage.getItem("MYPROJECT_ORDER")) {
        localStorage.setItem("MYPROJECT_ORDER", "-lastModifiedAt");
    }

    //初始化
    $scope.name = "";
    $scope.isPublic = false;
    $scope.description = "";
    $scope.keyword = "";
    $scope.thumbnail;
    $scope.isUpdating = false;
    $scope.categories = [];
    $scope.myProjects = [];
    $scope.inviteMode = false;
    $scope.folderId = "";
    $scope.callback;
    $scope.selectProjects = [];
    $scope.orderBy = localStorage.getItem("MYPROJECT_ORDER") || "-lastModifiedAt";

    $scope.$on("ADD_PROJECT_TO_COURSE", function(e, params) {
        $ngModal.openModal("add-porject-modal");
        $scope.selectProjects = [];
        $scope.callback = params.callback;
        $scope.folderId = "";
        $scope.inviteMode = false;
        loadPorjects();
        loadFolders();
       
    });

    $scope.$watch("orderBy", function(order, old) {
        if (order == old) return;
        localStorage.setItem("MYPROJECT_ORDER", order);
    });

    function loadFolders() {
        $scope.isLoadingFolder = true;
        FolderFactory.getFolders().then(function(folders) {
            $scope.isLoadingFolder = false;
            $scope.folders = folders;
            $scope.folders.forEach(function(folder) {
                if ($scope.folderId == folder._id) {
                    $scope.currentFolder = folder;
                }
            });
        });
    };
    // 載入專案
    function loadPorjects() {
        $scope.isLoading = true;
        if ($scope.folderId) {
            FolderFactory.listProjects($scope.folderId).then(function(projects) {
                $scope.myProjects = projects;
                $scope.isLoading = false;
            });
        } else if ($scope.inviteMode) {
            MyProjectFactory.getInvitedProjects().then(function(projects) {
                $scope.myProjects = projects;
                $scope.isLoading = false;
            });
        } else {
            MyProjectFactory.getProjects($scope.isPublic).then(function(projects) {
                $scope.myProjects = projects;
                $scope.isLoading = false;
            });
        }
    }

    function close() {
        $scope.name = "";
        $scope.isPublic = false;
        $scope.description = "";
        $scope.thumbnail;
        $scope.categories = [];
        $scope.isCreating = false;
        $ngModal.closeModal("add-porject-modal");
    };

    function getCheckedCategoryIds() {
        var checkeds = $scope.categories.filter(function(category) {
            if (category.checked) {
                return true;
            }
            return false;
        });
        var categoryIds = checkeds.map(function(c) {
            return c._id;
        });
        return categoryIds;
    };

    $scope.uploadThumbnail = function($file) {
        $scope.uploadingThumbnail = true;
        var upload = MyProjectFactory.uploadThumbnail($file);
        upload.then(function(resp) {
            $scope.thumbnail = resp.data.Location;
            $scope.uploadingThumbnail = false;
        }, function(resp) {
            $scope.uploadingThumbnail = false;
        }, function(evt) {
            $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
            $scope.fileName = evt.config.data.file.name;
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
        });
    };

    $scope.update = function() {
        $scope.isUpdating = true;
        var categoryIds = getCheckedCategoryIds();
        MyProjectFactory.update({
            id: $scope.projectId,
            name: $scope.name,
            description: $scope.description,
            categories: categoryIds.join(),
            thumbnail: $scope.thumbnail,
            isPublic: $scope.isPublic,
        }).then(function(data) {
            // $scope.callback(data.data);
            $scope.isUpdating = false;
            close();
        });
    };

    $scope.switch = function(type) {
        // $scope.selectProjects = [];
        if (type == "myProjects") {
            $scope.folderId = "";
            $scope.inviteMode = false;

        } else if (type == "inviteMode") {
            $scope.inviteMode = true;
            $scope.folderId = "";

        }
        loadPorjects();
        loadFolders();
    }

    $scope.selectProject = function(p) {
        if (!$scope.isSelected(p)) {
            $scope.selectProjects.push(p);
        }
        else {
            var idx = $scope.selectProjects.indexOf(p);
            $scope.selectProjects.splice(idx, 1);
        }
    }

    $scope.isSelected = function (project) {
        for (var i = 0; i < $scope.selectProjects.length; i++) {
            var p = $scope.selectProjects[i];
            if (p._id == project._id) {
                return true;
            }
        }
        return false;
    };

    $scope.getInFolder = function(folderId) {
        // $scope.selectProjects = [];
        $timeout(function() {
            $scope.keyword = "";
            $scope.myProjects = [];
            $scope.folderId = folderId;
            // $scope.isPublic = urlParams.hasOwnProperty("public");
            // $scope.trashMode = urlParams.hasOwnProperty("trash");
            // $scope.inviteMode = urlParams.hasOwnProperty("invites");
            $scope.isLoadingFolder = true;
            FolderFactory.getFolders().then(function(folders) {
                $scope.isLoadingFolder = false;
                $scope.folders.forEach(function(folder) {
                    if ($scope.folderId == folder._id) {
                        $scope.currentFolder = folder;
                    }
                });
            });
            loadPorjects();
        }, 10);
    };

    $scope.addToCourse = function() {
        $scope.isUpdating = true;
        if ($scope.selectProjects) {

            $scope.callback($scope.selectProjects);
        }
        $timeout(function() {
            $scope.isUpdating = false;
            close();

        }, 800);

    }

    // 關鍵字過濾
    $scope.searchFilter = function(project) {

        if (project) {

            var isMatch = false,
                keywords = $scope.keyword.split(" ");

            for (var i = 0; i < keywords.length; i++) {

                var keyword = keywords[i].toLowerCase();

                var keywords = project.keywords && project.keywords.toLowerCase() || "",
                    name = project.name && project.name.toLowerCase(),
                    description = project.description && project.description.toLowerCase();

                if (keywords.indexOf(keyword) != -1 ||
                    name.indexOf(keyword) != -1 ||
                    description.indexOf(keyword) != -1) {
                    isMatch = true;
                } else {
                    isMatch = false;
                    return false;
                }
            }

            return isMatch;
        }
        return false;
    };
});
