app.controller("ProductsController", function($scope, $http, $timeout, $location, UserFactory) {

    $scope.isSaving = false;

    $scope.loadUserInfo = function() {

        $scope.user = {};

        $http.get("/api/member/info", {}).success(function(member) {
            if (member) {
                angular.extend($scope.user, member);
            }
        });

    };

    $scope.gender = function(g) {
        debugger
        $scope.user.gender = g;
    };

    $scope.save = function() {

        var params = {
            
            email: $scope.user.email,
            picture: $scope.user.picture,
            name: $scope.user.name,
            bio: $scope.user.bio,
            schoolName: $scope.user.schoolName,
            teaching: $scope.user.teaching,
            facebook: $scope.user.facebook,
            twitter: $scope.user.twitter,
            googlePlus: $scope.user.googlePlus,
            linkedin: $scope.user.linkedin,
            blog: $scope.user.blog,
            website: $scope.user.website
                // jobTitle: $scope.user.jobTitle,
                // gender: $scope.user.gender,
                // birthday: $scope.user.birthday,
                // address: $scope.user.address,
                // companyTel: $scope.user.companyTel,
                // companyTelExt: $scope.user.companyTelExt,
                // homeTel: $scope.user.homeTel,
                // mobile: $scope.user.mobile,
        };

        $scope.isSaving = true;

        UserFactory.saveProfile(params).then(function(resp) {
            $scope.isSaving = false;
			$scope.errMsg = resp.data.error;
            if (!resp.error && !$scope.errMsg) {
                $scope.isSuccess = true;
                $scope.isFailure = false;
				$scope.errType = false;
            } else {
                $scope.isSuccess = false;
                $scope.isFailure = true;
                $scope.errType = resp.data.type;
            }
            $timeout(function() {
                $scope.isSuccess = false;
                $scope.isFailure = false;
            }, 2000);

        });


    };

    $scope.uploadAvatar = function($file) {
        $scope.uploadingAvatar = true;
        var upload = UserFactory.uploadAvatar($file);
        upload.then(function(resp) {
            if (resp.data) {
                $scope.user.avatar = resp.data.avatar;
                UserFactory.user.avatar = $scope.user.avatar;
            }
            $scope.uploadingAvatar = false;
        }, function(resp) {
            $scope.uploadingAvatar = false;
        }, function(evt) {
            $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
            $scope.fileName = evt.config.data.file.name;
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
        });
    };

    $scope.updatePwd = function() {

        if (!$scope.newPassword || $scope.newPassword != $scope.newRePassword) return;

        $scope.isEditingPassword = true;

        $http({
            url: "/api/member/update-password",
            method: "POST",
            data: $.param({
                email: $scope.user.email,
                oldPassword: $scope.oldPassword,
                password: $scope.newPassword,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).success(function(data) {
            if (!data.error) {
                $scope.oldPassword = "";
                $scope.newPassword = "";
                $scope.newRePassword = "";
                $scope.isEditPassword = false;
                $scope.isEditingPassword = false;
                $scope.isSuccess = true;
                $scope.isFailure = false;
            } else {
                $scope.isEditingPassword = false;
                $scope.isSuccess = false;
                $scope.isFailure = true;
            }
            $timeout(function() {
                $scope.isSuccess = false;
                $scope.isFailure = false;
            }, 2000);
        }).error(function(data, status, headers, config) {
            $scope.isSending = false;
            $scope.isSuccess = false;
            $scope.isFailure = true;
        });
    };

    $scope.init = function() {
        $scope.loadUserInfo();
    };
});
