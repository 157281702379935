app.controller("OrgCourseInfoController", function($q, $scope, $rootScope, $http, $timeout, $location, UserFactory, ProjectFactory, SealedCourseFactory, RedeemFactory, CategoryFactory) {

    $scope.courseId = $("#course").attr("course-id");
    $scope.isLiked = false;
    $scope.isLoading = true;
    $scope.cloneSuccess = false;
    $scope.isFollow = false;
    $scope.user = undefined;
    $scope.toast = {
        isOpen: false,
        color: undefined,
        msg: undefined,
        link: undefined,
    };

    function showToast(msg, link, color, duraction) {
        $scope.toast.msg = msg;
        $scope.toast.isOpen = true;
        $scope.toast.color = color;
        $scope.toast.link = link;
        $timeout(function () {
            $scope.toast.isOpen = false;
            $scope.toast.msg = undefined;
            $scope.toast.color = undefined;
            $scope.toast.link = undefined;
        }, duraction);
    };

    function loadAuthor(userId) {
        UserFactory.info(userId).then(function(data) {
            if (!data.data.error) {
                $scope.author = data.data;
            }
        });
    };

    function loadCourse() {
        var deferred = $q.defer();
        SealedCourseFactory.info($scope.courseId).then(function(data) {
            $scope.course = data.data;
            deferred.resolve();
        });
        return deferred.promise;
    };

	$scope.UserFactory = UserFactory;

    $scope.init = function() {

        UserFactory.getUser().then(function(user) {
            if (user._id) {
                $scope.user = user;
                $scope.author = loadAuthor(user._id);
            } else {
                $scope.user = undefined;
            }
        });

		var unwatch = $scope.$watch('UserFactory.user', function(newValue, oldValue) {
			if(newValue){
				var UserID = newValue._id;
				// console.log('UserID: ' + UserID);
				dataLayer.push({'UserID': UserID});
			}
		});

        loadCourse();

    };

    $scope.like = function () {
        SealedCourseFactory.like($scope.courseId).then(function (data) {
            var result = data.data;
            if (!result.error) {
                $scope.course.isLiked = true;
                $scope.course.likeCount++;
            }
        });
    };

    $scope.unlike = function () {
        SealedCourseFactory.unlike($scope.courseId).then(function (data) {
            var result = data.data;
            if (!result.error) {
                $scope.course.isLiked = false;
                $scope.course.likeCount--;
            }
        });
    };

	$scope.cloneCourse = function() {
		if (!UserFactory.user._id) return;
		$rootScope.$broadcast("CLONE_ITEM", {
			item: $scope.course,
			callback: function(result) {
				// 成功複製
                if(result.success) {
                    showToast("複製成功", '/mycourse', 'blue', 2500);
    				// TDAPP.onEvent("Sealed-Course-Clone", $scope.course._id);
                }
                else {
                    showToast(result.msg, undefined, 'red', 2500);
                }
			}
		});
	};
	// 列印課程
    $scope.printCourse = function(orgId) {
    	if (!UserFactory.user._id) return;
    	window.open('/org/'+orgId+'/course-print/'+$scope.courseId);
	};
	
    $scope.follow = function() {
		UserFactory.follow($scope.author._id).then(function(data) {
			var result = data.data;
			if (!result.error) {
				$scope.author.isFollow = true;
			}
		});
	};

	$scope.unfollow = function() {
		UserFactory.unfollow($scope.author._id).then(function(data) {
			var result = data.data;
			if (!result.error) {
				$scope.author.isFollow = false;
			}
		});
	};

});
