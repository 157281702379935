app.controller("UpdateCourseController", function ($scope, $ngModal, CategoryFactory, MyCourseFactory, CourseFactory) {

	//初始化
	var attachmentItem = {
		_id: undefined,
		name: '',
		url: '',
		progress: 0,
		isUploading: false,
		err: undefined,
		spKey: undefined
	};
	$scope.attachmentTypes = [
		'application/pdf', // pdf
		'application/vnd.ms-powerpoint', // ppt
		'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
		'application/msword', // doc
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
		'application/vnd.ms-excel', // xls
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' //xlsx
	];
	clear();

	function clear() {
		$scope.courseId = undefined;
		$scope.name = undefined;
		$scope.description = undefined;
		$scope.thumbnail = undefined;
		$scope.isUpdating = false;
		$scope.isCreating = false;
		$scope.categories = [];
		$scope.projectData = {
			isPublic: false,
			allowClone: true
		};
		$scope.attachments = [];
		$ngModal.closeModal("update-course-modal");
	}

	function initialCourse(course) {
		$scope.name = course.name;
		$scope.description = course.description;
		$scope.thumbnail = course.thumbnail;
		$scope.thumbnailName = course.thumbnail && course.thumbnail.split(/[/]+/).pop();
		$scope.categories.forEach(function (category, j) {
			$scope.categories[j].checked = false;
			if (course.categories && course.categories.length > 0) {
				course.categories.forEach(function (isCheckedCourse, i) {
					if (isCheckedCourse._id == category._id) {
						$scope.categories[j].checked = true;
					}
				});
			}
		});

		course.attachments = course.attachments || [];
		console.log(course);
		$scope.attachments = course.attachments.map(function (attachment) {
			return angular.extend({}, attachmentItem, {
				_id: attachment.usageId,
				name: attachment.originalName,
				spKey: attachment.spKey
			});
		});
		console.log($scope.attachments);
	}

	function getCheckedCategoryIds() {
		var checkeds = $scope.categories.filter(function (category) {
			if (category.checked) {
				return true;
			}
			return false;
		});
		var categoryIds = checkeds.map(function (c) {
			return c._id;
		});
		return categoryIds;
	}

	$scope.$on("UPDATE_COURSE", function (e, course, callback) {
		$scope.callback = callback;
		$scope.course = course;
		$scope.courseId = course._id;
		$scope.tags = [];

        let tags = course.tags;
		if (tags) {
			$scope.tags = tags.map(function(tag) {
				return {
					text: tag
				};
			});
		}

		// 載入 Categories
		CategoryFactory.getCategories().then(function (categories) {

			$scope.categories = angular.copy(categories);

			//讀取專案
			initialCourse(course);
			$ngModal.openModal("update-course-modal");
		});
	});

	$scope.uploadThumbnail = function ($file) {
		$scope.uploadingThumbnail = true;
		var upload = MyCourseFactory.uploadThumbnail($file);
		upload.then(function (resp) {
			$scope.backgroundColor = resp.data.backgroundColor;
			$scope.thumbnail = resp.data.Location;
			$scope.uploadingThumbnail = false;
		}, function (resp) {
			$scope.uploadingThumbnail = false;
		}, function (evt) {
			$scope.progress = parseInt(100.0 * evt.loaded / evt.total);
			$scope.fileName = evt.config.data.file.name;
			console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
		});
	};

	$scope.uploadAttachment = function ($file) {
		if (!$file) {
			return;
		}

		var attachment = angular.extend({}, attachmentItem);
		var upload = MyCourseFactory.uploadAttachment($scope.courseId, $file);

		attachment.isUploading = true;
		$scope.attachments.push(attachment);
		upload.then(function (resp) {
			console.log('>>> Success!!!');
			console.log(resp);
			resp = resp.data;
			if (resp.success) {
				var data = resp.data,
					link = data.link,
					info = data.info;
				attachment._id = link._id;
				attachment.url = info.url;
				attachment.isUploading = false;
			} else {
				attachment.err = resp.msg;
			}
		}, function (resp) {
			attachment.err = resp;
			attachment.isUploading = false;
		}, function (evt) {
			attachment.progress = parseInt(100.0 * evt.loaded / evt.total);
			attachment.name = evt.config.data.file.name;
		});
	};

	$scope.removeAttachment = function (attachmentId) {
		_.remove($scope.attachments, {
			_id: attachmentId
		});
	};

	$scope.update = function () {
		$scope.isUpdating = true;
		var categoryIds = getCheckedCategoryIds();
		var tags = $scope.tags.map(function (tag) {
				return tag.text;
			}),
			attachments = $scope.attachments.map(function (attachment) {
				return attachment._id;
			});
		MyCourseFactory.update({
			id: $scope.courseId,
			name: $scope.name,
			description: $scope.description,
			categories: categoryIds.join(),
			tags: tags,
			thumbnail: $scope.thumbnail,
			backgroundColor: $scope.backgroundColor,
			attachments: attachments
		}).then(function (data) {
			$scope.callback(data.data);
			$scope.isUpdating = false;
			clear();
		});
	};

	$scope.getDownloadAttachmentUrl = function (spKey, fileName) {
		return CourseFactory.getDownloadAttachmentUrl($scope.projectId, spKey, fileName);
	};
});
