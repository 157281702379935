/**
 * Created by Dominic Lee on 2017/11/23.
 */

app.controller('OrgProjectAuhtorController', function ($rootScope, $scope, $ngModal, UserFactory, ProjectFactory) {

	$scope.isFollow = false;
	$scope.authorId = $("#author").attr("author-id");

	function loadAuthor(userId) {
		UserFactory.info(userId).then(function(data) {
			if (!data.data.error) {
				$scope.author = data.data;
			}
		});
	};


	$scope.follow = function() {
		UserFactory.follow($scope.author._id).then(function(data) {
			var result = data.data;
			if (!result.error) {
				$scope.author.isFollow = true;
			}
		});
	};

	$scope.unfollow = function() {
		UserFactory.unfollow($scope.author._id).then(function(data) {
			var result = data.data;
			if (!result.error) {
				$scope.author.isFollow = false;
			}
		});
	};

	(function() {
		loadAuthor($scope.authorId);
	})();
});
