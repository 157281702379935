app.controller("SendCourseController", function($scope, $timeout, $ngModal, InviteFactory, UserFactory, MyCourseFactory, InviteCourseFactory) {

    $scope.email = "";
    $scope.name = "";
    $scope.isPublic = 'false';
    $scope.description = "";
    $scope.thumbnail;
    $scope.isSending = false;
    $scope.receivers = [];
    $scope.newReceivers = [];
    $scope.removeInvites = [];

    $scope.$on("SEND_COURSE", function(e, params) {
        $scope.callback = params.callback;
        $scope.course = params.course;
        $scope.receivers = [];
        $scope.newReceivers = [];
        $scope.removeInvites = [];
        listInvites();
        $ngModal.openModal("send-course-modal");
    });

	$scope.selectedObject = function (item) {
		// console.log(item);
		var email = item.description;
		addReceiver (email);
	};

    $scope.onKeyup = function(event) {

        var keyCode = event.keyCode;
        if (keyCode == 13) {
            if (is.email($scope.email)) {
                addReceiver($scope.email);
            }
        }
    };

    $scope.onPaste = function(event) {
        $timeout(function() {
            if (is.email($scope.email)) {
                addReceiver($scope.email);
            }
        }, 200);
    };

    $scope.remove = function(receiver) {
        var idx = $scope.newReceivers.indexOf(receiver);
        $scope.newReceivers.splice(idx, 1);
    };

    $scope.addToRemoves = function(receiver) {
        var idx = $scope.receivers.indexOf(receiver);
        $scope.removeInvites.push(receiver._id);
        $scope.receivers.splice(idx, 1);
    };

    function addReceiver(email) {
        UserFactory.getUser().then(function(info) {
            if (info.email == email) {
                $scope.callback("TYPE_OWN_NAME");
            } else {
                UserFactory.infoByEmail(email).then(function(data) {
                    var result = data.data;
                    if (result.error) {

                    } else {
                        $scope.newReceivers.push(result);
                        $scope.email = "";
                    }
                });
            }
        });
    };





    function close() {
        $scope.name = "";
        $scope.isPublic = false;
        $scope.description = "";
        $scope.thumbnail;
        $scope.isSending = false;
        $ngModal.closeModal("send-course-modal");
    };

    function listInvites() {
        InviteFactory.listInvites($scope.course._id).then(function(data) {
            if (!data.data.error) {
                $scope.receivers = data.data;
            }
        });
    };

    $scope.send = function() {
        $scope.isSending = true;
        InviteCourseFactory.send([$scope.course], $scope.newReceivers, $scope.removeInvites).then(function(data) {
            $scope.isSending = false;
            var result = data.data;
            if (result.error) {
                return;
            }
            close();
        });
        // $timeout(function() {
        //     $scope.isSending = false;
        //     close();
        // }, 500);

    };


});
