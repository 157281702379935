app.controller("CreateCourseController", function($scope, $ngModal, CategoryFactory, MyProjectFactory, MyCourseFactory) {

    $scope.name = "";
    $scope.description = "";
    $scope.thumbnail = undefined;
    $scope.isCreating = false;
    $scope.categories = [];
    $scope.tags = [];
    $scope.uploadingThumbnail = false;
    $scope.progress = undefined;

    $scope.$on("NEW_COURSE", function(e, params) {
        $scope.callback = params.callback;
        $scope.name = "";
        $scope.description = "";
        $scope.thumbnail = "";
        $scope.isCreating = false;
        $scope.categories = [];
        $scope.tags = [];
        $scope.uploadingThumbnail = false;
        $scope.progress = undefined;

        // 載入 Categories
        CategoryFactory.getCategories().then(function(categories) {
            $scope.categories = angular.copy(categories);
        });

        $ngModal.openModal("create-course-modal");
    });

    function close() {
        $scope.name = "";
        $scope.description = "";
        $scope.thumbnail = "";
        $scope.isCreating = false;
        $scope.categories = [];
        $scope.tags = [];
        $scope.uploadingThumbnail = false;
        $scope.progress = undefined;
        $ngModal.closeModal("create-course-modal");
    }

    function getCheckedCategoryIds() {
        var checkeds = $scope.categories.filter(function(category) {
            if (category.checked) {
                return true;
            }
            return false;
        });
        var categoryIds = checkeds.map(function(c) {
            return c._id;
        });
        return categoryIds;
    }

    $scope.uploadThumbnail = function($file) {
        $scope.uploadingThumbnail = true;
        var upload = MyCourseFactory.uploadThumbnail($file);
        upload.then(function(resp) {
            $scope.backgroundColor = resp.data.backgroundColor;
            $scope.thumbnail = resp.data.Location;
            $scope.uploadingThumbnail = false;
        }, function(resp) {
            $scope.uploadingThumbnail = false;
        }, function(evt) {
            $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
            $scope.fileName = evt.config.data.file.name;
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
        });
    };

    $scope.create = function() {

        if (!$scope.name) {
            $scope.emptyName = true;
            return;
        } else {
            $scope.emptyName = false;
        }

        $scope.isCreating = true;
        var categoryIds = getCheckedCategoryIds();
        var tags = $scope.tags.map(function(tag) {
            return tag.text;
        });


        //後端catergory防呆
        var newCoursecCard = {
            name: $scope.name,
            description: $scope.description,
            tags: tags,
            thumbnail: $scope.thumbnail,
            folderId: $scope.folderId,
            backgroundColor: $scope.backgroundColor
        };
        if (categoryIds.join() != "")
            newCoursecCard.categories = categoryIds.join();

        MyCourseFactory.create(newCoursecCard).then(function(data) {
            if (!data.data.error) {
                $scope.callback(data.data);
                // TDAPP.onEvent("Cell-Create");
                close();
            }
        });
    };
});
