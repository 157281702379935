app.controller("PolicyController", function($scope, $http, $timeout, $location, $ngModal, $auth, UserFactory) {

    $scope.email = "";
    $scope.password = "";
    $scope.name = "";
    $scope.isSignuping = false;
    $scope.isPlicyShow = false;
    $scope.isPlicyconfirm = false;



    $scope.$on("SHOW_POLICY", function(e, params) {
        $ngModal.openModal("policy-confirm-modal");
        $scope.callback = params.callback;
    });


    $scope.plicyConfirm = function() {
    	$scope.isPlicyconfirm = false;
        $ngModal.closeModal("policy-confirm-modal");
        $scope.callback(true);
    }

    $scope.plicyCancel = function() {
    	$scope.isPlicyconfirm = false;
        $ngModal.closeModal("policy-confirm-modal");
        $scope.callback(false);
    }

});
