app.controller("ForgetPwdController", function($scope, $http, $timeout, $ngModal, UserFactory) {

    $scope.send = function() {

        if (!$scope.email) return;
        if (!is.email($scope.email)) {
            $scope.emailFormatError = true;
            return;
        }
        $scope.emailFormatError = false;
        $scope.isSending = true;
        $scope.notVerify = false;
        $scope.notSend = false;
        UserFactory.forgetPwd($scope.email).then(function(data) {
            $scope.isSending = false;
            var result = data.data;
            if (result.error) {
                if (result.error == 1) {
                    $scope.notVerify = true;
                }
                else {
                    $scope.notSend = true;
                }
            } 
            else {
                $timeout(function() {
                    $ngModal.closeModal("forgetpwd-modal");
                }, 250);
                $ngModal.openModal("resetpwd-sended-modal");
                $scope.notVerify = false;
                $scope.notSend = false;
            }
        });
    };
});
