/**
 * Created by Tinny Wang on 2017/9/1.
 */

app.controller('ManageController', function($rootScope, $scope, $location, $timeout, $routeParams, NavFactory){

	$rootScope.promptMsgConfig = {
		msg: '提示訊息',
		icon: 'zmdi-info-outline',
		iconColor: '#50BFFF'
	};
	$rootScope.editForm = {};
	$rootScope.isSubSearch = false;
	
	$scope.filterFields = [];
	
	var unwatch = $scope.$watchGroup(['activeType', 'activeName'], function (newValue, oldValue) {
		if(newValue){
			var types = $scope.activeType.split(':'),
				names = $scope.activeName.split(':');
			$rootScope.navType = types[0];
			$rootScope.subType = types[1];
			$rootScope.navTypeName = names[0];
			$rootScope.subTypeName = names[1];
			$scope.activeName = modifyActiveName($scope.activeName);
			unwatch();
		}
	});

	$scope.$watch('tableFilter', function (newValue, oldValue) {
		$scope.$broadcast('TABLE_FILTER', {
			filterFields: $scope.filterFields,
			filterVal: newValue
		});
	});
	
	$scope.switchType = function(activeType, activeName){
		$scope.activeType = activeType;
		$scope.activeName = modifyActiveName(activeName);
	};

	$scope.addNewFn = function () {
		$scope.$broadcast("FN_NEW", {
			type: $routeParams.type,
			subType: $routeParams.subType
		});
		NavFactory.openEditingOperator();
	};
	
	$rootScope.promptMsg = function(promptMsgConfig) {
		$rootScope.promptMsgConfig = promptMsgConfig;
		$scope.$broadcast("PROMPT_MSG", {
			callback: function() {
				$rootScope.prompting = true;
				$timeout(function() {
					$rootScope.prompting = false;
				}, 4000);
			}
		});
	};

	$scope.$on('PROMPT_MSG', function (e, params) {
		params.callback();
	});
	
	function modifyActiveName(activeName){
		var name = activeName.split(':');
		name = '<span>'
			+ name.join('</span> <i class="zmdi zmdi-chevron-right" style="color: #4a4a4a;"></i> <span>')
			+ '</span>';
		return name;
	}
});
