/**
 * Created by Dominic Lee on 2017/11/23.
 */

app.controller('OrgProjectViewerController', function ($rootScope, $scope, $ngModal, UserFactory, ProjectFactory) {
	$scope.pageOver = false;
	$scope.projectId = $("#project").attr("project-id");
	$scope.isFullScreenMode = false;

	$scope.toogleFullScreen = function() {
		$scope.isFullScreenMode = !$scope.isFullScreenMode;
		$scope.$broadcast("resize");
	};
});
