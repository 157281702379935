/**
 * Created by Tinny Wang on 2017/8/31.
 */

app.controller("SettingController", function ($rootScope, $scope, $routeParams, DataDisplayFactory, ManageSettingFactory,
											  OrgFactory, NavFactory, EditFormFactory, OrgCategoryFactory) {
	NavFactory.setScope($scope);

	var publisherId = $rootScope.publisherId;
	
	$scope.activeRow = null;
	$scope.info = {};
	$scope.cols = NavFactory.setting.manager;
	$scope.modal = {};
	$scope.formItems = [];

	function promptError(msg) {
		$rootScope.promptMsg({
			msg: msg,
			icon: 'zmdi-alert-circle-o',
			iconColor: '#ff5075'
		});
	}
	
	$scope.isAdmin = function (admin) {
		$scope.isAdmin = !!(admin);
	};

	$scope.delFn = function () {
		wrapBlockTipFn($scope.ManageSetting.doRemove, {
			msg: '已刪除',
			icon: 'zmdi-info-outline',
			iconColor: '#ffb450'
		});
	};

	$scope.cancelFn = function () {
		DataDisplayFactory.closeBlockTip();
	};

	$scope.$on("FN_NEW", function (e, params) {
		$scope.ManageSetting.useAddNew($scope, {
			formParam: $scope.formParam,
			publisherId: $rootScope.publisherId
		});
		wrapConfirmFn(true);
	});

	$scope.$on("FN_OPERATOR", function (e, params) {
		var thisRow = params.row;
		$scope.activeRow = thisRow;
		$scope.ManageSetting.useUpdate($scope, {
			publisherId: $rootScope.publisherId,
			formParam: $scope.formParam,
			row: thisRow
		});
		wrapConfirmFn();
	});

	$scope.$on("NAV_SUCCESS", function (e, params) {
		$scope.navType = params.navType || $rootScope.navType;
		$scope.subType = params.subType || $rootScope.subType;
		_init(params.scope, $scope.navType, $scope.subType);
	});

	function _init(scope, navType, subType) {
		$scope.navScope = scope;
		$scope.ManageSetting = ManageSettingFactory[navType];

		$scope.activeRow = null;
		$scope.modal = {};
		$scope.formItems = [];
		$scope.formParam = {};

		if (navType == 'manager') {
			useCategory(function () {
				initData(scope);
			});
		} else if (navType == 'publisher') {
			_getInfo($scope);
		}

		var display = $scope.ManageSetting.display;
		if (display) {
			$scope.$parent.isSubSearch = !!(display.search);
			$scope.$parent.isAddNew = !!(display.addBtn);
			$scope.$parent.searchPlaceholder = display.search && display.search.placeholder;
			$scope.$parent.addNewName = display.addBtn && display.addBtn.name;
			$scope.$parent.filterFields = display.search && display.search.filterFields || [];
		} else {
			$scope.$parent.isSubSearch = false;
			$scope.$parent.isAddNew = false;
			$scope.$parent.searchPlaceholder = null;
			$scope.$parent.addNewName = null;
			$scope.$parent.filterFields = [];
		}
	}

	function useData(scope, refresh) {
		var promise = $scope.ManageSetting.getData(scope, {
			publisherId: $rootScope.publisherId,
			formParam: $scope.formParam
		});
		promise.then(function (resp) {
			var data = null;
			if (resp.success) {
				data = resp.data;
				scope.tableData = data.results || [];
				if (refresh) {
					$scope.$broadcast('TABLE_RESET', {
						data: scope.tableData
					});
				}

				_getInfo(scope, true, function () {

				});
			}
		});
	}

	function initData(scope) {
		$scope.formParam = {
			categoryOptions: $scope.categories
		};
		if (!$scope.ManageSetting.getFormParam) {
			useData(scope);
			return;
		}
		let promise = $scope.ManageSetting.getFormParam(scope, {
			publisherId: $rootScope.publisherId
		});
		promise.then(function (resp) {
			if (resp.success) {
				$scope.formParam = angular.extend({}, $scope.formParam, resp.data);
				useData(scope);
			}
		});
	}

	function wrapConfirmFn(refresh) {
		if (typeof $scope.confirmFn === 'function') {
			var oriFn = $scope.confirmFn;
			$scope.confirmFn = function (editForm) {
				var _this = this;
				var promise = oriFn(editForm);
				promise.then(function (resp) {
					var promptMag = '修改完成';
					if (resp.success) {
						_this.closeModal();
						if (refresh) {
							useData($scope.navScope, refresh);
							promptMag = '新增完成';
						}
						$rootScope.promptMsg({
							msg: promptMag,
							icon: 'zmdi-cloud-done',
							iconColor: '#50BFFF'
						});
					} else {
						promptError(resp.msg);
					}

				});
			};
		}
	}

	function wrapBlockTipFn(blockTipFn, promptMsgConfig) {
		if (blockTipFn) {
			var promise = blockTipFn($scope.navScope, {
				publisherId: $rootScope.publisherId,
				row: $scope.activeRow
			});

			promise.then(function (resp) {
				if (resp.success) {
					_.remove($scope.navScope.tableData, function (item) {
						return $scope.activeRow === item;
					});
					DataDisplayFactory.closeBlockTip();
					$rootScope.promptMsg(promptMsgConfig);
				} else {
					promptError(resp.msg);
				}
			});
		}
	}

	function useCategory(callback) {
		OrgCategoryFactory.list($rootScope.publisherId)
			.then(function (resp) {
				resp = resp.data;
				if (resp.success) {
					let data = resp.data.results,
						tempArr = [];
					data.forEach(function (category) {
						tempArr.push({
							label: category.name,
							value: category._id
						});
					});
					$scope.categories = tempArr;
					callback();
				} else {
					promptError(resp.msg);
				}

			});
	}

	function _getInfo(scope, getAdmin, callback) {
		OrgFactory.info(publisherId)
			.then(function (resp) {
				resp = resp.data;
				let data = resp.data;
				if(!resp.success){
					promptError(resp.msg);
					return;
				}
				if (getAdmin) {
					let admin = data.administrator;
					admin = angular.extend({}, admin, {
						role: {
							createdAt: data.createdAt,
							label: '主要管理者'
						},
						fnTypes: [] // TODO 等待API新增屬性參數
					});
					scope.tableData.unshift(admin);
					scope.admin = admin;
					callback();
				} else {
					scope.info.name = data.name;
					scope.info.description = data.description;
					scope.info.avatar = data.avatar;
					scope.info.website = data.website;
					scope.info.facebook = data.facebook;
					scope.info.googlePlus = data.googlePlus;
					scope.info.youtube = data.youtube;
					scope.info.color = data.color;
				}
			});
	}

	$scope.updateInfo = function () {
		if ($scope.tempAvatar) {
			OrgFactory.uploadAvatar(publisherId, $scope.tempAvatar)
				.then(function (resp) { // success
					resp = resp.data;
					if (resp.success) {
						$scope.info.avatar = resp.data.img;
						_updateInfo();
					} else {
						promptError(resp.msg);
					}
				}, function (resp) { // failed
					promptError('圖片上傳失敗');
				});
		} else {
			_updateInfo();
		}
	};

	function _updateInfo() {
		OrgFactory.update(publisherId, $scope.info)
			.then(function (resp) {
				resp = resp.data;
				if (resp.success) {
					$rootScope.promptMsg({
						msg: '儲存成功',
						icon: 'zmdi-cloud-done',
						iconColor: '#50BFFF'
					});
				} else {
					promptError(resp.msg);
				}
			});
	}


});

app.factory('ManageSettingFactory', function ($q, $http, $rootScope, $timeout, EditFormFactory, OrgMemberFactory) {

	var _factory = {};
	var EditForm = EditFormFactory.setting;

	function useEditForm(scope, mode) {
		scope.modal = mode.modal;
		scope.formItems = mode.form;
	}

	function dealingResp(resp, deferred, callback) {
		if (resp.status == 200) {
			var data = callback(resp.data) || resp.data;
			deferred.resolve(data);
		} else {
			deferred.reject('Failed!');
		}
	}

	_factory.manager = {
		display: {
			search: {
				placeholder: '搜尋管理者',
				filterFields: ['name', 'email']
			},
			addBtn: {
				name: '新增管理者'
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.listOtherAdmin(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					// TODO 等待API新增屬性參數
					var fnLists = scope.isAdmin ? ['del'] : [],
						results = data.data.results || [];
					results.forEach(function (member, idx) {
						data.data.results[idx].fnTypes = fnLists;
					});
				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.manager.getNewMode(params.formParam);
			useEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var member = editForm.member._id,
					categories = editForm.categories || [];

				var deferred = $q.defer();
				OrgMemberFactory.createOtherAdmin(params.publisherId, member, {
					categories: categories
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var row = params.row;
			var deferred = $q.defer();
			OrgMemberFactory.removeOtherAdmin(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					return data;
				});
			});
			return deferred.promise;
		}
	};

	_factory.publisher = {};

	return _factory;
});
