app.controller("LoginController", function ($scope, $timeout, $http, $ngModal) {
    $scope.mode = 'login';

    $scope.email = "";
    $scope.password = "";
    $scope.repassword = "";
    $scope.teach = "";
    $scope.school = "";
    $scope.name = "";

    $scope.reset = function () {
        $scope.email = "";
        $scope.password = "";
        $scope.repassword = "";
        $scope.teach = "";
        $scope.school = "";
        $scope.name = "";
    };

    $scope.changeMode = function (mode) {
        $scope.mode = mode || $scope.mode;
    };
    $timeout(function () {
        // 自動補齊有發生
        var auto = $("#login-email:-webkit-autofill");
        if (auto.length && auto.val().length > 0) {
            $scope.expand = true;
        }
    }, 100);

    $scope.loginKeyup = function (event) {
        if (event.keyCode == 13) {
            $scope.login();            
        }
    };

    $scope.login = function () {

        $scope.isLoginFailure = false;
        $scope.isLoginEmailFailure = !is.email($scope.email);
        if ($scope.isLoginEmailFailure) return;

        $scope.isLogining = true;
        $http({
            url: "/member/login",
            method: "POST",
            data: $.param({
                email: $scope.email,
                password: $scope.password,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).success(function(data) {
            if (!data.error) {
                // location.href = "/";
                location.reload();
            }
            else {
                $scope.isLogining = false;
                $scope.isLoginFailure = true;
            }
        }).error(function(data, status, headers, config) {
            $scope.isLogining = false;
            $scope.isLoginFailure = true;
        });
    };

    $scope.signup = function () {

        $scope.isSignupFailure = false;
        $scope.isSignupNameFailure = ($scope.name.length <= 0);
        $scope.isSignupEmailFailure = !is.email($scope.email);
        $scope.isSignupPasswordFailure = ($scope.password != $scope.repassword);

        if ($scope.isSignupEmailFailure || $scope.isSignupNameFailure || $scope.isSignupPasswordFailure) return;

        if (!$scope.mobile || !$scope.teach || !$scope.school) {
            alert("請填寫聯絡電話、學校名稱、任教科目！");
            return;
        }

        $scope.isSiguping = true;
        $http({
            url: "/member/signup",
            method: "POST",
            data: $.param({
                email: $scope.email,
                password: $scope.password,
                mobile: $scope.mobile,
                school: $scope.school,
                teach: $scope.teach,
                name: $scope.name
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).success(function(data) {
            if (!data.error) {
                $timeout(function () {
                    $scope.isSiguping = false;
                    $scope.isSignupFailure = false;
                    $scope.isRepeatSignup = false;
                    $ngModal.openModal("signup-success-modal");
                    $scope.reset();
                    $scope.mode = "login";
                }, 500);
            }
            else {
                $scope.isSiguping = false;
                $scope.isSignupFailure = true;
                $scope.isRepeatSignup = true;
            }
        }).error(function(data, status, headers, config) {
            $scope.isSiguping = false;
            $scope.isFailure = true;
        });
    };

});

app.controller("SignupSuccessController", function ($scope, $timeout, $http) {
    $scope.startAnimation = false;

    $scope.$on("OPEN_MODAL_signup-success-modal", function (e) {
        $scope.startAnimation = true;
    });

    $scope.$on("CLOSE_MODAL_signup-success-modal", function (e) {
        $scope.startAnimation = false;
    });
});

app.controller("RegisterSuccessController", function ($scope, $timeout, $http) {
    $timeout(function () {
        $scope.isOpen = true;
        $scope.startAnimation = true;
    }, 500);
});

app.controller("ResetPasswordController", function ($scope, $timeout, $http, $location) {

    $scope.email = $location.search().email;
    $scope.token = $location.search().token;

    $timeout(function () {
        $scope.isOpen = true;
        $scope.startAnimation = true;
    }, 500);

    $scope.send = function () {

        if (!$scope.password || $scope.password != $scope.repassword) return;
        $scope.isSending = true;
        $http({
            url: "/member/updatePwd",
            method: "POST",
            data: $.param({
                token: $scope.token,
                email: $scope.email,
                password: $scope.password,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).success(function(data) {
            if (!data.error) {
                $scope.isSending = false;
                $scope.isSuccess = true;
                $scope.isFailure = false;
            }
            else {
                $scope.isSending = false;
                $scope.isFailure = true;
            }
        }).error(function(data, status, headers, config) {
            $scope.isSending = false;
            $scope.isFailure = true;
        });
    };
});


