app.controller("CourseMoveToTrashController", function($scope, $ngModal, MyCourseFactory, CategoryFactory) {

    $scope.isRemoving = false;

    $scope.$on("MOVE_TRASH_COURSE", function(e, params) {
        $scope.callback = params.callback;
        $scope.courseIds = params.courseIds;
        console.log(params.courseIds);
        $ngModal.openModal("course-move-trash-modal");
        Mousetrap.bind('enter', function(e) {
            $scope.$apply(function() {
                $scope.remove();
            })
        });
    });

    function close() {
        $scope.courseIds = undefined;
        $scope.isRemoving = false;
        $ngModal.closeModal("course-move-trash-modal");
        Mousetrap.unbind('enter');
    };

    $scope.remove = function() {
        $scope.isRemoving = true;

        MyCourseFactory.moveToTrash({
            courseIds: $scope.courseIds.join()
        }).then(function(data) {
        	console.log(data);
            $scope.callback(data.data, $scope.course);
            close();
        });
    };
});
