app.controller("ExploreController", function($scope, $rootScope, $location, $timeout, ProjectFactory, CategoryFactory) {

    if (!localStorage.getItem("EXPLORE_ORDER")) {
        localStorage.setItem("EXPLORE_ORDER", "-viewCount");
    }

    var urlParams = $location.search();
    $scope.categoryId = urlParams.category;
    $scope.activityMode = urlParams.hasOwnProperty("activity");
    $scope.keyword = urlParams.keyword || "";
    $scope.searchKeyword = $scope.keyword;
    $scope.orderBy = localStorage.getItem("EXPLORE_ORDER") || "-viewCount";
    $scope.noMoreProject = false;
    $scope.isLoading = false;
    $scope.categories = [];
    $scope.projects = [];
    $scope.start = 0;
    $scope.len = 16;

    if ($scope.orderBy === '-lastModifiedAt') {
        $scope.orderBy = "-lastModifiedAt&&isRecommend";
    }

    $scope.reload = function (orderBy) {
        $scope.orderBy = orderBy;
        $scope.start = 0;
        $scope.len = 16;
        reset();
        $scope.isLoading = true;

        $timeout(function () {
            var urlParams = $location.search();
            $scope.categoryId = urlParams.category;
            $scope.activityMode = urlParams.hasOwnProperty("activity");
            $scope.keyword = urlParams.keyword;
            $scope.projects = [];
            loadPorjects();
            loadCategories();
            if (!$scope.keyword) {
                $scope.searchKeyword = "";
            }
        }, 100);
    };

    $scope.onInfiniteScroll = function () {
        if ($scope.isLoading) return;
        loadPorjects();
    };

    $scope.onSearchKeyup = function (event) {
        var keyCode = event.keyCode;
        if (keyCode == 13) {
            $timeout(function () {
                $scope.keyword = $scope.searchKeyword;
                $location.search({
                    keyword: $scope.keyword
                });
                $scope.reload();
            }, 300);
        }
    };

    // 載入專案
    function loadPorjects() {
        $scope.isLoading = true;
        if ($scope.categoryId) {
            ProjectFactory.getCategoryProjects($scope.categoryId, $scope.start, $scope.len, $scope.orderBy).then(function (data) {
                appendProjects(data.data);
            });
        }
        else if ($scope.activityMode) {
            ProjectFactory.getFollowedProjects($scope.start, $scope.len, $scope.orderBy).then(function (data) {
                appendProjects(data.data);
            });
        }
        else if ($scope.keyword) {
            ProjectFactory.searchPublic($scope.start, $scope.len, $scope.keyword, $scope.orderBy).then(function (data) {
                appendProjects(data.data);
            });
        }
        else {
            ProjectFactory.getPromoteProjects($scope.start, $scope.len, $scope.orderBy).then(function(data) {
                $scope.isLoading = false;
                Array.prototype.push.apply($scope.projects, data.data);
                $scope.noMoreProject = true;
            });
        }
    }

    function appendProjects (projects) {
        $scope.isLoading = false;
        if (projects.length > 0) {
            $scope.start += projects.length;
            Array.prototype.push.apply($scope.projects, projects);
        }
        else {
            $scope.noMoreProject = true;
        }
    }

    // 載入分類列表
    function loadCategories () {
        CategoryFactory.getCategories().then(function (categories) {
            $scope.categories = categories;
            $scope.categories.forEach(function (category) {
                if ($scope.categoryId == category._id) {
                    $scope.currentCategory = category;
                }
            });
        });
    };

    function reset () {
        $scope.noMoreProject = false;
        $scope.projects = [];
        $scope.start = 0;
        $scope.len = 16;
    };

    $scope.init = function() {
        // reset();
        // loadPorjects();
        // loadCategories();
    };
});
