app.controller("ApplyMoreCourseController", function($scope, $timeout, $ngModal, InviteFactory, UserFactory, MyCourseFactory, InviteCourseFactory, TicketFactory) {


    $scope.isSending = false;
    $scope.applied = false;

    $scope.$on("APPLY_MORE_COURSE", function(e, params) {
        $scope.callback = params.callback;
        // $scope.course = params.course;
        // $scope.receivers = [];
        // $scope.newReceivers = [];
        // $scope.removeInvites = [];

        UserFactory.getUser().then(function(data) {
            console.log(data);
            if (data._id){
				$scope.user = data;
			}
            if (data.ticketCourseMaxId && data.ticketCourseMaxId.status == "Created") {
                $scope.applied = true;
            }
            $ngModal.openModal("apply-more-course-modal");

        });



    });


    $scope.apply = function() {
        $scope.isSending = true;

        TicketFactory.applyMoreCoure({
            number: 50
        }).then(function(data) {
            $scope.isSending = false;
            $scope.applied = true;
            $scope.callback();
        });
        // $timeout(function() {
        //     $scope.callback();
        //     $scope.isSending = false;
        //     $scope.applied = true;

        // }, 500);
    };
    $scope.onKeyup = function(event) {

        var keyCode = event.keyCode;
        if (keyCode == 13) {
            if (is.email($scope.email)) {
                addReceiver($scope.email);
            }
        }
    };

    $scope.onPaste = function(event) {
        $timeout(function() {
            if (is.email($scope.email)) {
                addReceiver($scope.email);
            }
        }, 200);
    };



    function close() {
        $scope.name = "";
        $scope.isPublic = false;
        $scope.description = "";
        $scope.thumbnail;
        $scope.isSending = false;
        $ngModal.closeModal("apply-more-course-modal");
    };


});
