/**
 * Created by Tinny Wang on 2017/11/16.
 */

app.controller('OrgAuthorController', function ($scope, $ngModal, UserFactory) {
	
	$scope.followToggle = function (e) {
		var followFn, memberId, followed, $info,
			followToggleClass = 'followToggle',
			followToggleSelector = '.' + followToggleClass,
			$target = angular.element(e.target),
			$targetParent = $target.parent(followToggleSelector);
		
		if(!$target.hasClass(followToggleClass)){
			if($targetParent.hasClass(followToggleClass)){
				$target = $targetParent;
			} else {
				return;
			}
		}

		$info = $target.parent('.info');
		memberId = $info.attr('m-id');
		followed = $target.attr('followed') == 'true';
		followFn = !followed ? UserFactory.follow : UserFactory.unfollow;
		
		followFn(memberId).then(function (resp) {
			if(resp.status === 200){
				resp = resp.data;
				if(resp.success){
					$target.attr('followed', !followed);
					$info.find(followToggleSelector).toggleClass('hidden');
				}else{
					$ngModal.openModal('login-modal');
				}
			}
		});
	};
	
});
