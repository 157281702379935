/**
 * Created by Tinny Wang on 2017/10/19.
 */

app.controller('OrganizationController', function ($rootScope, $scope, $http, $timeout, $location, UserFactory, OrgFactory) {
	
	$scope.category = $location.search().c || 'home';
	$scope.courses = [];
	$scope.projects = [];
	
	$scope.navInit = function(){
		$scope.category = $location.search().c || 'home';
		console.log($scope.category);
	};
	
	$scope.switchCategory = function(category){
		$scope.category = category;
		$location.search('c', category);

		getCourses(category, 4);
		getProjects(category, 8);
	};
	
	$scope.showMore = function(category, type){
		if ('course' == type){
			getCourses(category, 24);
		}else if ('project' == type){
			getProjects(category, 24);
		}
	};

	function getCourses(category, len, page) {
		OrgFactory.listCourses(category, {
			len: len,
			page: page
		}).then(function (data) {
			$scope.courses = data;
		});
	}
	
	function getProjects(category, len, page) {
		OrgFactory.listProjects(category, {
			len: len,
			page: page
		}).then(function (data) {
			$scope.projects = data;
		});
	}
	
});
