app.controller("GalleryController", function($rootScope, $scope, $timeout) {

    $scope.isOpen = false;
    $scope.$on("OPEN_GALLERY", function(e, params) {
        $scope.isOpen = true;
        $scope.images = params.images;
        $scope.current = params.images[params.index];
    });

    $scope.close = function() {
        $scope.isOpen = false;
        $scope.images = [];
    };
    $scope.change = function(index) {
        $scope.current = $scope.images[index];
    };
});