/**
 * Created by Tinny Wang on 2017/8/17.
 */

app.controller('EnterCoverController', function ($scope, $cookies, $timeout) {

	// deleteCookie('stopEnterCover', '/');

	$scope.title = '全新的 JIBAO 首頁上線了，趕緊看看有什麼不同吧!~';
	$scope.coverContents = [
		{
			description: '請注意!!~新版首頁將我的專案與我的課程移至個人資訊區了唷!!',
			img: '/images/enter-cover/新版頁面圖素.png'
		},
		{
			description: '',
			img: '/images/enter-cover/新版頁面圖素2.png'
		}
	];
	
	$scope.isShow = $cookies.get('stopEnterCover') === 'true' || false;
	$scope.totalPage = $scope.coverContents.length;
	$scope.currentPage = 1;

	$scope.triggerNext = function(){
		$timeout(function() {
			angular.element(document.querySelector('.enter-cover .swiper-button-next')).click();
			// angular.element('.swiper-button-next').triggerHandler('click');
			if($scope.currentPage !== $scope.totalPage){
				$scope.currentPage += 1;
			}
		});
	};

	$scope.triggerPrev = function(){
		$timeout(function() {
			angular.element(document.querySelector('.enter-cover .swiper-button-prev')).click();
			if($scope.currentPage !== 1){
				$scope.currentPage -= 1;
			}
		});
	};
	
	if($cookies.get('stopEnterCover') === 'true'){
		$scope.isOpen = false;
	}else{
		$scope.isOpen = true;
	}

	$scope.stopEnterCover = function () {
		setCookie('stopEnterCover', $scope.isShow, 90);
	};

	$scope.$on("OPEN_MODAL_enter-modal", function (e) {
		$scope.isOpen = true;
	});

	$scope.$on("CLOSE_MODAL_enter-modal", function (e) {
		$scope.isOpen = false;
	});

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		var expires = "expires="+ d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function deleteCookie( name, path, domain ) {
		if( getCookie( name ) ) {
			document.cookie = name + "=" +
				((path) ? ";path="+path:"")+
				((domain)?";domain="+domain:"") +
				";expires=Thu, 01 Jan 1970 00:00:01 GMT";
		}
	}
});
