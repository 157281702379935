app.controller("AddExploreProjectToCourseController", function($scope, $ngModal, $timeout, MyProjectFactory, CategoryFactory, ProjectFactory, FolderFactory, ExploreFactory) {

    if (!localStorage.getItem("EXPLORE_PROJECT_ORDER")) {
        localStorage.setItem("EXPLORE_PROJECT_ORDER", "-lastModifiedAt");
    }


    //初始化
    $scope.name = "";
    $scope.isPublic = false;
    $scope.description = "";
    $scope.keyword = "";
    $scope.thumbnail;
    $scope.isUpdating = false;
    $scope.categories = [];
    $scope.myProjects = [];
    $scope.inviteMode = false;
    $scope.callback;
    $scope.selectProjects = [];
    $scope.currentCategory = 0;
    $scope.allProjects;
    $scope.allSearchMode = false;
    $scope.orderBy = localStorage.getItem("EXPLORE_PROJECT_ORDER") || "-lastModifiedAt";

    $scope.$on("ADD_EXPLORE_PROJECT_TO_COURSE", function(e, params) {
        $ngModal.openModal("add-explore-porject-modal");
        $scope.selectProjects = [];
        $scope.callback = params.callback;
        $scope.inviteMode = false;
        loadPorjects($scope.orderBy);
        switchPorjects();
        loadCategories();


    });

    // $scope.$watch("keyword", function(order, old) {
    //     if (order == old) return;
    //     if ($scope.allSearchMode) {
    //         searchAllExplore();
    //     }

    // });


    $scope.$watch("orderBy", function(order, old) {
        if (order == old) return;
        localStorage.setItem("EXPLORE_PROJECT_ORDER", order);
        loadPorjects($scope.orderBy);
    });





    // 當關鍵字有更動發生時（例如新增、修改、刪除關鍵字）
    $scope.$watch("keyword", debounce(function(newValue, oldValue) {
        if (oldValue == newValue) return;
        if (!newValue || newValue.length == 0) return;
        if ($scope.allSearchMode) {
            searchAllExplore();
        }
    }, 300), true);


    // 載入分類列表
    function loadCategories() {
        CategoryFactory.getCategories().then(function(categories) {
            $scope.categories = categories;
            $scope.categories.forEach(function(category) {
                if ($scope.categoryId == category._id) {
                    $scope.currentCategory = category;
                }
            });
            // console.log($scope.categories);
        });
    };





    // 載入專案
    function loadPorjects(orderBy) {
        //得到一堆專案照count排
        ExploreFactory.listProjects(orderBy).then(function(projects) {
            $scope.allProjects = projects;
        });

    }


    function switchPorjects() {
        if ($scope.currentCategory == 1) {
            $scope.allSearchMode = true;
            $scope.myProjects = $scope.allProjects;
            $scope.myProjects = [];
            searchAllExplore();

        } else if ($scope.currentCategory == 0) {
            $scope.allSearchMode = false;
            ExploreFactory.getPromoteProjects().then(function(projects) {
                $scope.isLoading = true;
                $scope.myProjects = projects;
                $scope.isLoading = false;
            });
        } else {
            //得到一堆專案照count排
            $scope.allSearchMode = false;
            $scope.isLoading = true;
            $scope.myProjects = $scope.allProjects.filter(function(p) {
                var flag = 0;
                if (p.categories && p.categories.length > 0) {
                    p.categories.forEach(function(projectCategory) {
                        if (projectCategory._id == $scope.currentCategory._id) {
                            flag = 1;
                        }
                    });
                }
                if (flag) return p;
            });
            $scope.isLoading = false;
            // console.log($scope.myProjects);

        }
    }





    function searchAllExplore() {
        $scope.isLoading = true;
        ProjectFactory.searchPublic(0, 200, $scope.keyword, $scope.orderBy).then(function(data) {
            // console.log(data.data);
            $scope.myProjects = data.data;
            $scope.isLoading = false;
        });
    }

    function close() {
        $scope.name = "";
        $scope.isPublic = false;
        $scope.description = "";
        $scope.thumbnail;
        $scope.categories = [];
        $scope.isCreating = false;
        $ngModal.closeModal("add-explore-porject-modal");
    };

    function getCheckedCategoryIds() {
        var checkeds = $scope.categories.filter(function(category) {
            if (category.checked) {
                return true;
            }
            return false;
        });
        var categoryIds = checkeds.map(function(c) {
            return c._id;
        });
        return categoryIds;
    };




    $scope.switch = function(c) {
        // console.log(c);
        $scope.currentCategory = c;
        switchPorjects();
    }

    $scope.selectProject = function(p) {
        if (!$scope.isSelected(p)) {
            $scope.selectProjects.push(p);
        } else {
            var idx = $scope.selectProjects.indexOf(p);
            $scope.selectProjects.splice(idx, 1);
        }
    }

    $scope.isSelected = function(project) {
        for (var i = 0; i < $scope.selectProjects.length; i++) {
            var p = $scope.selectProjects[i];
            if (p._id == project._id) {
                return true;
            }
        }
        return false;
    };

    // $scope.getInFolder = function(folderId) {
    //     // $scope.selectProjects = [];
    //     $timeout(function() {
    //         $scope.keyword = "";
    //         $scope.myProjects = [];
    //         $scope.folderId = folderId;
    //         // $scope.isPublic = urlParams.hasOwnProperty("public");
    //         // $scope.trashMode = urlParams.hasOwnProperty("trash");
    //         // $scope.inviteMode = urlParams.hasOwnProperty("invites");
    //         $scope.isLoadingFolder = true;
    //         FolderFactory.getFolders().then(function(folders) {
    //             $scope.isLoadingFolder = false;
    //             $scope.folders.forEach(function(folder) {
    //                 if ($scope.folderId == folder._id) {
    //                     $scope.currentFolder = folder;
    //                 }
    //             });
    //         });
    //         switchPorjects();
    //     }, 10);
    // };

    $scope.addToCourse = function() {
        $scope.isUpdating = true;
        if ($scope.selectProjects) {

            $scope.callback($scope.selectProjects);
        }
        $timeout(function() {
            $scope.isUpdating = false;
            close();

        }, 800);

    }

    // 關鍵字過濾
    $scope.searchFilter = function(project) {

        if (project) {

            var isMatch = false,
                keywords = $scope.keyword.split(" ");

            for (var i = 0; i < keywords.length; i++) {

                var keyword = keywords[i].toLowerCase();

                var keywords = project.keywords && project.keywords.toLowerCase() || "",
                    name = project.name && project.name.toLowerCase(),
                    description = project.description && project.description.toLowerCase();

                if ((keywords.indexOf(keyword) && keywords.indexOf(keyword) != -1) ||
                    name.indexOf(keyword) != -1 ||
                    (description && description.indexOf(keyword) != -1)) {
                    isMatch = true;
                } else {
                    isMatch = false;
                    return false;
                }
            }

            return isMatch;
        }
        return false;
    };
});
