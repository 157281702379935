/**
 * Created by Tinny Wang on 2017/9/2.
 */

app.controller('ContentController', function ($rootScope, $scope, $routeParams, $timeout,
											  NavFactory, ManageContentFactory, DataDisplayFactory, OrgCategoryFactory) {

	$scope.selectedObject = function (item) {
		
	};

	NavFactory.setScope($scope);

	function promptError(msg) {
		$rootScope.promptMsg({
			msg: msg,
			icon: 'zmdi-alert-circle-o',
			iconColor: '#ff5075'
		});
	}

	$scope.acceptFn = function () {
		wrapBlockTipFn($scope.ManageContent.doAccept, {
			msg: '已接受',
			icon: 'zmdi-check',
			iconColor: '#13CE66'
		});
	};

	$scope.rejectFn = function () {
		wrapBlockTipFn($scope.ManageContent.doReject, {
			msg: '已拒絕',
			icon: 'zmdi-info-outline',
			iconColor: '#ffb450'
		});
	};

	$scope.delFn = function () {
		wrapBlockTipFn($scope.ManageContent.doRemove, {
			msg: '已刪除',
			icon: 'zmdi-info-outline',
			iconColor: '#ffb450'
		});
	};

	$scope.cancelFn = function () {
		DataDisplayFactory.closeBlockTip();
	};

	$scope.$on("FN_NEW", function (e, params) {
		$scope.ManageContent.useAddNew($scope, {
			formParam: $scope.formParam,
			publisherId: $rootScope.publisherId
		});
		wrapConfirmFn(true);
	});

	$scope.$on("FN_OPERATOR", function (e, params) {
		var thisRow = params.row;
		$scope.activeRow = thisRow;
		$scope.ManageContent.useUpdate($scope, {
			publisherId: $rootScope.publisherId,
			formParam: $scope.formParam,
			row: thisRow
		});
		wrapConfirmFn();
	});

	$scope.$on("NAV_SUCCESS", function (e, params) {
		$scope.navType = params.navType || $rootScope.navType;
		$scope.subType = params.subType || $rootScope.subType;
		_init(params.scope, $scope.navType, $scope.subType);
	});

	function _init(scope, navType, subType) {
		$scope.navScope = scope;
		$scope.ManageContent = ManageContentFactory[navType][subType];

		$scope.activeRow = null;
		$scope.modal = {};
		$scope.formItems = [];
		$scope.formParam = {};

		useCategory(function () {
			initData(scope);
		});

		var display = $scope.ManageContent.display;
		if (display) {
			$scope.$parent.isSubSearch = !!(display.search);
			$scope.$parent.isAddNew = !!(display.addBtn);
			$scope.$parent.searchPlaceholder = display.search && display.search.placeholder;
			$scope.$parent.addNewName = display.addBtn && display.addBtn.name;
			$scope.$parent.filterFields = display.search && display.search.filterFields || [];
		} else {
			$scope.$parent.isSubSearch = false;
			$scope.$parent.isAddNew = false;
			$scope.$parent.searchPlaceholder = null;
			$scope.$parent.addNewName = null;
			$scope.$parent.filterFields = [];
		}

	}

	function useData(scope, refresh) {
		var promise = $scope.ManageContent.getData(scope, {
			publisherId: $rootScope.publisherId,
			formParam: $scope.formParam
		});
		promise.then(function (resp) {
			var data = null;
			if (resp.success) {
				data = resp.data;
				scope.tableData = data.results || [];
				if (refresh) {
					$scope.$broadcast('TABLE_RESET', {
						data: scope.tableData
					});
				}
			}
		});
	}

	function initData(scope) {
		$scope.formParam = {
			categoryOptions: $scope.categories
		};
		if (!$scope.ManageContent.getFormParam) {
			useData(scope);
			return;
		}
		let promise = $scope.ManageContent.getFormParam(scope, {
			publisherId: $rootScope.publisherId
		});
		promise.then(function (resp) {
			if (resp.success) {
				$scope.formParam = angular.extend({}, $scope.formParam, resp.data);
				useData(scope);
			}
		});
	}

	function wrapConfirmFn(refresh) {
		if (typeof $scope.confirmFn === 'function') {
			var oriFn = $scope.confirmFn;
			$scope.confirmFn = function (editForm) {
				var _this = this;
				var promise = oriFn(editForm);
				promise.then(function (resp) {
					var promptMag = '修改完成';
					if (resp.success) {
						_this.closeModal();
						if (refresh) {
							useData($scope.navScope, refresh);
							promptMag = '新增完成';
						}
						$rootScope.promptMsg({
							msg: promptMag,
							icon: 'zmdi-cloud-done',
							iconColor: '#50BFFF'
						});
					} else {
						promptError(resp.msg);
					}

				});
			};
		}
	}

	function wrapBlockTipFn(blockTipFn, promptMsgConfig) {
		if (blockTipFn) {
			var promise = blockTipFn($scope.navScope, {
				publisherId: $rootScope.publisherId,
				row: $scope.activeRow
			});

			promise.then(function (resp) {
				if (resp.success) {
					_.remove($scope.navScope.tableData, function (item) {
						return $scope.activeRow === item;
					});
					DataDisplayFactory.closeBlockTip();
					$rootScope.promptMsg(promptMsgConfig);
				} else {
					promptError(resp.msg);
				}
			});
		}
	}

	function useCategory(callback) {
		OrgCategoryFactory.list($rootScope.publisherId)
			.then(function (resp) {
				resp = resp.data;
				if (resp.success) {
					let data = resp.data.results,
						tempArr = [];
					data.forEach(function (category) {
						tempArr.push({
							label: category.name,
							value: category._id
						});
					});
					$scope.categories = tempArr;
					callback();
				} else {
					promptError(resp.msg);
				}
			});
	}
});

app.factory('ManageContentFactory', function ($q, $http, $rootScope, $timeout, EditFormFactory,
											  OrgMemberFactory, OrgCategoryFactory, OrgCourseFactory, OrgProjectFactory, OrgAdFactory) {

	var _factory = {};
	var EditForm = EditFormFactory.content;

	function useEditForm(scope, mode) {
		scope.modal = mode.modal;
		scope.formItems = mode.form;
	}

	function refreshEditForm(scope, mode, newParams) {
		$rootScope.editForm = angular.extend({}, newParams || {});
		useEditForm(scope, mode);
	}

	function dealingResp(resp, deferred, callback) {
		if (resp.status == 200) {
			var data = callback(resp.data) || resp.data;
			deferred.resolve(data);
		} else {
			deferred.reject('Failed!');
		}
	}

	function _checkImg(oldImg, newImg, uploadImgFn, params, callback) {
		if ((oldImg != newImg) && !!newImg) {
			uploadImgFn(newImg, params).then(function (uploadResp) {
				var imgUrl = undefined;
				if (uploadResp.status == 200) {
					uploadResp = uploadResp.data;
					if (!uploadResp.success) {
						return;
					}
					imgUrl = uploadResp.data.Location;
				}
				callback(imgUrl);
			});
		} else {
			callback();
		}
	}

	// ---------- category ---------- //

	_factory.category = {};

	_factory.category.all = {
		display: {
			search: {
				placeholder: '搜尋分類（名稱）',
				filterFields: ['name']
			},
			addBtn: {
				name: '新增分類'
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgCategoryFactory.list(params.publisherId).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.category.all.getNewMode(params.formParam);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				OrgCategoryFactory.create(params.publisherId, {
					name: editForm.categoryName,
					isPublic: (editForm.isPublic || false)
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			var mode = EditForm.category.all.getEditMode(params.formParam, params.row);
			var row = params.row;
			refreshEditForm(scope, mode, {
				categoryName: row.name,
				isPublic: row.isPublic
			});
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				OrgCategoryFactory.update(params.publisherId, row._id, {
					name: editForm.categoryName,
					isPublic: editForm.isPublic
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						params.row.name = editForm.categoryName;
						params.row.isPublic = editForm.isPublic;
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var row = params.row;
			var deferred = $q.defer();
			OrgCategoryFactory.remove(params.publisherId, row._id)
				.then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
			return deferred.promise;
		}
	};

	// ---------- member ---------- //

	_factory.member = {};

	_factory.member.all = {
		display: {
			search: {
				placeholder: '搜尋（名稱、email、分類）',
				filterFields: ['name', 'email', 'categories:array.name']
			},
			// addBtn: {
			// 	name: '新增會員'
			// }
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.list(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.member.all.getNewMode(params.formParam);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var member = editForm.member._id,
					categories = editForm.categories || [];

				var deferred = $q.defer();
				OrgMemberFactory.create(params.publisherId, member, {
					categories: categories
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			var mode = EditForm.member.all.getEditMode(params.formParam, params.row);
			refreshEditForm(scope, mode, {
				categories: _.map(params.row.categories, '_id')
			});
			scope.confirmFn = function (editForm) {
				var row = params.row;
				var deferred = $q.defer();
				OrgMemberFactory.update(row.publisher, row._id, {
					categories: editForm.categories
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						data = data.data;
						row.categories = data.categories;
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var row = params.row;
			var deferred = $q.defer();
			OrgMemberFactory.remove(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					return data;
				});
			});
			return deferred.promise;
		},
		// getFormParam: function (scope, params) {
		// 	var deferred = $q.defer();
		// 	$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
		// 		dealingResp(resp, deferred, function (data) {
		// 			data = {
		// 				success: true,
		// 				data: {
		// 					options: [
		// 						{label: '音樂', value: '58286efc08bf04b84d4d7251'},
		// 						{label: '美術', value: '58286efc08bf04b84d4d7259'}
		// 					]
		// 				}
		// 			};
		// 			return data;
		// 		});
		// 	});
		// 	return deferred.promise;
		// }
	};

	_factory.member.audit = {
		display: {
			search: {
				placeholder: '搜尋（名稱、email）',
				filterFields: ['name', 'email']
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.list(params.publisherId, {
				status: 'PENDING'
			}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		doAccept: function (scope, params) {
			var deferred = $q.defer(),
				row = params.row;
			OrgMemberFactory.accept(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		doReject: function (scope, params) {
			var deferred = $q.defer(),
				row = params.row;
			OrgMemberFactory.reject(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		}
	};

	// ---------- project ---------- //

	_factory.project = {};

	_factory.project.all = {
		display: {
			search: {
				placeholder: '搜尋（名稱、作者、分類）',
				filterFields: ['project.name', 'author.name', 'categories:array.name']
			},
			// addBtn: {
			// 	name: '新增專案'
			// }
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgProjectFactory.listVerified(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.project.all.getNewMode(params.formParam);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var projectId = editForm.project._id,
					categories = editForm.categories || [],
					isPublic = editForm.isPublic;

				var deferred = $q.defer();
				OrgProjectFactory.create(params.publisherId, projectId, {
					categories: categories,
					isPublic: isPublic
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			var currRow = params.row,
				currAuth = currRow.auth,
				mode = EditForm.project.all.getEditMode(params.formParam, currRow);
			currAuth = currAuth === undefined || currAuth === null ? -1 : currAuth;
			refreshEditForm(scope, mode, {
				categories: _.map(currRow.categories, '_id'),
				auth: currAuth
			});
			scope.confirmFn = function (editForm) {
				var row = params.row,
					categories = editForm.categories,
					auth = editForm.auth;
				var deferred = $q.defer();
				OrgProjectFactory.update(row.publisher, row._id, {
					categories: categories,
					auth: auth
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						data = data.data;
						row.categories = data.categories;
						row.auth = data.auth;
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var row = params.row;
			var deferred = $q.defer();
			OrgProjectFactory.remove(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					
				});
			});
			return deferred.promise;
		}
	};

	_factory.project.recommend = {
		display: {
			search: {
				placeholder: '搜尋（名稱、作者、分類）',
				filterFields: ['project.name', 'author.name', 'categories:array.name']
			},
			addBtn: {
				name: '新增推薦'
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgProjectFactory.listRecommend(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.project.recommend.getNewMode(params.formParam);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var projectId = editForm.project;

				var deferred = $q.defer();
				OrgProjectFactory.recommend(params.publisherId, projectId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var row = params.row;
			var deferred = $q.defer();
			OrgProjectFactory.verified(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					
				});
			});
			return deferred.promise;
		},
		getFormParam: function (scope, params) {
			var deferred = $q.defer();
			OrgProjectFactory.listVerified(params.publisherId, {
				perpage: 1000 // TODO 暫時以專案全列的方式顯示選單
			}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					var oriData = data.data.results,
						options = [], tmp;

					for (var i = 0; i < oriData.length; i++) {
						tmp = oriData[i];
						options.push({
							label: tmp.project.name,
							value: tmp._id
						});
					}
					return {
						success: true,
						data: {
							options: options
						}
					};
				});
			});
			return deferred.promise;
		}
	};

	_factory.project.audit = {
		display: {
			search: {
				placeholder: '搜尋（名稱、作者）',
				filterFields: ['project.name', 'author.name']
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgProjectFactory.listPending(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {

		},
		doReject: function (scope, params) {
			var deferred = $q.defer();
			OrgProjectFactory.reject(params.publisherId, params.row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					
				});
			});
			return deferred.promise;
		},
		doAccept: function (scope, params) {
			var deferred = $q.defer();
			OrgProjectFactory.verified(params.publisherId, params.row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					
				});
			});
			return deferred.promise;
		}
	};

	// ---------- course ---------- //

	_factory.course = {};

	_factory.course.all = {
		display: {
			search: {
				placeholder: '搜尋（名稱、作者、分類）',
				filterFields: ['course.name', 'author.name', 'categories:array.name']
			},
			// addBtn: {
			// 	name: '新增課程'
			// }
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgCourseFactory.listVerified(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.course.all.getNewMode(params.formParam);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer(),
					courseId = editForm.course._id,
					categories = editForm.categories || [],
					isPublic = editForm.isPublic;
				OrgCourseFactory.create(params.publisherId, courseId, {
					categories: categories,
					isPublic: isPublic
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			var currRow = params.row,
				currAuth = currRow.auth,
				mode = EditForm.course.all.getEditMode(params.formParam, currRow);
			currAuth = currAuth === undefined || currAuth === null ? -1 : currAuth;
			refreshEditForm(scope, mode, {
				categories: _.map(currRow.categories, '_id'),
				auth: currAuth
			});
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer(),
					row = params.row,
					categories = editForm.categories,
					auth = editForm.auth;
				OrgCourseFactory.update(row.publisher, row._id, {
					categories: categories,
					auth: auth
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						data = data.data;
						row.categories = data.categories;
						row.auth = data.auth;
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var row = params.row;
			var deferred = $q.defer();
			OrgCourseFactory.remove(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					
				});
			});
			return deferred.promise;
		}
	};

	_factory.course.recommend = {
		display: {
			search: {
				placeholder: '搜尋（名稱、作者、分類）',
				filterFields: ['course.name', 'author.name', 'categories:array.name']
			},
			addBtn: {
				name: '新增推薦'
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgCourseFactory.listRecommend(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.course.recommend.getNewMode(params.formParam);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer(),
					courseId = editForm.course;
				OrgCourseFactory.recommend(params.publisherId, courseId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var row = params.row;
			var deferred = $q.defer();
			OrgCourseFactory.verified(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					
				});
			});
			return deferred.promise;
		},
		getFormParam: function (scope, params) {
			var deferred = $q.defer();
			OrgCourseFactory.listVerified(params.publisherId, {
				perpage: 1000 // TODO 暫時以課程全列的方式顯示選單
			}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					var oriData = data.data.results,
						options = [], tmp;

					for (var i = 0; i < oriData.length; i++) {
						tmp = oriData[i];
						options.push({
							label: tmp.course.name,
							value: tmp._id
						});
					}
					return {
						success: true,
						data: {
							options: options
						}
					};
				});
			});
			return deferred.promise;
		}
	};

	_factory.course.audit = {
		display: {
			search: {
				placeholder: '搜尋（名稱、作者）',
				filterFields: ['course.name', 'author.name']
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgCourseFactory.listPending(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {

		},
		doReject: function (scope, params) {
			var deferred = $q.defer();
			OrgCourseFactory.reject(params.publisherId, params.row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		doAccept: function (scope, params) {
			var deferred = $q.defer();
			OrgCourseFactory.verified(params.publisherId, params.row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		}
	};

	// ---------- author ---------- //

	_factory.author = {};

	_factory.author.all = {
		display: {
			search: {
				placeholder: '搜尋（名稱、email、分類）',
				filterFields: ['name', 'email', 'categories:array.name']
			},
			addBtn: {
				name: '新增作者'
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.listAuthor(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.author.all.getNewMode(params.formParam);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var member = editForm.member._id,
					categories = editForm.categories || [];

				var deferred = $q.defer();
				OrgMemberFactory.createAuthor(params.publisherId, member, {
					categories: categories
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			var mode = EditForm.author.all.getEditMode(params.formParam, params.row);
			refreshEditForm(scope, mode, {
				categories: _.map(params.row.categories, '_id')
			});
			scope.confirmFn = function (editForm) {
				var row = params.row;
				var deferred = $q.defer();
				OrgMemberFactory.updateAuthor(row.publisher, row._id, {
					categories: editForm.categories
				}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						row.categories = data.data.categories;
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var row = params.row;
			var deferred = $q.defer();
			OrgMemberFactory.removeAuthor(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		}
	};

	_factory.author.audit = {
		display: {
			search: {
				placeholder: '搜尋（名稱、email）',
				filterFields: ['name', 'email']
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.listAuthor(params.publisherId, {
				status: 'PENDING'
			}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer();
				$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
				return deferred.promise;
			};
		},
		doAccept: function (scope, params) {
			var deferred = $q.defer(),
				row = params.row;
			OrgMemberFactory.acceptAuthor(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		doReject: function (scope, params) {
			var deferred = $q.defer(),
				row = params.row;
			OrgMemberFactory.rejectAuthor(params.publisherId, row._id, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		}
	};

	// ---------- advertisement ---------- //

	_factory.advertisement = {};

	_factory.advertisement.home = {
		display: {
			search: {
				placeholder: '搜尋（名稱）',
				filterFields: ['name']
			},
			addBtn: {
				name: '新增廣告'
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgAdFactory.list(params.publisherId, 'banner')
				.then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.advertisement.home.getNewMode(params.formParam, params.row);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer(),
					orgId = params.publisherId;
				
				_checkImg(null, editForm.image, OrgAdFactory.uploadImg, {
					orgId: orgId
				}, function (imgUrl) {
					OrgAdFactory.create(orgId, 'banner', {
						name: editForm.name,
						linkUrl: editForm.linkUrl,
						image: {
							url: imgUrl
						},
						bkgColor: undefined,
						description: undefined
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {
							
						});
					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			var row = params.row,
				mode = EditForm.advertisement.home.getEditMode(params.formParam, row);
			refreshEditForm(scope, mode, {
				name: row.name,
				linkUrl: row.linkUrl,
				image: row.image.url
			});
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer(),
					orgId = params.publisherId,
					oldImg = row.image.url,
					newImg = editForm.image;
				
				_checkImg(oldImg, newImg, OrgAdFactory.uploadImg, {
					orgId: orgId
				}, function (imgUrl) {
					OrgAdFactory.update(orgId, row._id, {
						name: editForm.name,
						linkUrl: editForm.linkUrl,
						image: {
							url: imgUrl
						},
						bkgColor: undefined,
						description: undefined
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {
							var d = data.data;
							row.name = d.name;
							row.linkUrl = d.linkUrl;
							row.image.url = d.image.url;
						});
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var deferred = $q.defer(),
				orgId = params.publisherId;
			OrgAdFactory.remove(orgId, params.row._id)
				.then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
			return deferred.promise;
		}
	};

	_factory.advertisement.side = {
		display: {
			search: {
				placeholder: '搜尋（名稱）',
				filterFields: ['name']
			},
			addBtn: {
				name: '新增廣告'
			}
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgAdFactory.list(params.publisherId, 'side')
				.then(function (resp) {
					dealingResp(resp, deferred, function (data) {
						
					});
				});
			return deferred.promise;
		},
		useAddNew: function (scope, params) {
			var mode = EditForm.advertisement.side.getNewMode(params.formParam, params.row);
			refreshEditForm(scope, mode);
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer(),
					orgId = params.publisherId;

				_checkImg(null, editForm.image, OrgAdFactory.uploadImg, {
					orgId: orgId
				}, function (imgUrl) {
					OrgAdFactory.create(orgId, 'side', {
						name: editForm.name,
						linkUrl: editForm.linkUrl,
						image: {
							url: imgUrl
						},
						bkgColor: undefined,
						description: undefined
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {
							
						});
					});
				});
				return deferred.promise;
			};
		},
		useUpdate: function (scope, params) {
			var row = params.row,
				mode = EditForm.advertisement['side'].getEditMode(params.formParam, row);
			refreshEditForm(scope, mode, {
				name: row.name,
				linkUrl: row.linkUrl,
				image: row.image.url
			});
			scope.confirmFn = function (editForm) {
				var deferred = $q.defer(),
					orgId = params.publisherId,
					oldImg = row.image.url,
					newImg = editForm.image;

				_checkImg(oldImg, newImg, OrgAdFactory.uploadImg, {
					orgId: orgId
				}, function (imgUrl) {
					OrgAdFactory.update(orgId, row._id, {
						name: editForm.name,
						linkUrl: editForm.linkUrl,
						image: {
							url: imgUrl
						},
						bkgColor: undefined,
						description: undefined
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {
							var d = data.data;
							row.name = d.name;
							row.linkUrl = d.linkUrl;
							row.image.url = d.image.url;
						});
					});
				});
				return deferred.promise;
			};
		},
		doRemove: function (scope, params) {
			var deferred = $q.defer(),
				orgId = params.publisherId;
			OrgAdFactory.remove(orgId, params.row._id)
				.then(function (resp) {
					dealingResp(resp, deferred, function (data) {

					});
				});
			return deferred.promise;
		}
	};

	return _factory;
});
