app.controller("RemoveFolderController", function ($scope, $ngModal, FolderFactory, CategoryFactory) {

	$scope.isRemoving = false;

	$scope.$on("REMOVE_FOLDER", function (e, params) {
		$scope.callback = params.callback;
		$scope.folder = params.folder;
		$ngModal.openModal("remove-folder-modal");
		Mousetrap.bind('enter', function(e) {
            $scope.$apply(function() {
            	$scope.remove();
            })
        });
	});

	function close () {
		$scope.folder = undefined;
		$scope.isRemoving = false;
		$ngModal.closeModal("remove-folder-modal");
		Mousetrap.unbind('enter');
	};

	$scope.remove = function () {
		$scope.isRemoving = true;
		FolderFactory.remove({
			id: $scope.folder._id
		}).then(function (data) {
			$scope.callback(data.data, $scope.folder);
			close();
		});
	};
});
