app.controller("UserController", function ($rootScope, $scope, $http, $timeout, $location, UserFactory, CourseFactory, ProjectFactory, SealedCourseFactory, SealedProjectFactory) {

	function setLimitNum() {
		if($scope.favoriteType == false){
			$scope.limitNum = 8;
		}else{
			$scope.limitNum = undefined;
		}
	}
	
	$scope.userId = $("#user").attr("user-id");
	$scope.origin = DOMAIN.default;
	$scope.user = {};
	$scope.isFollowed = false;
	$scope.isLoading = true;
	$scope.isAuthor = false;

	$scope.bannerDragMode = false;
	$scope.offsetY = 50;
	UserFactory.info($scope.userId).then(function (data) {
		var result = data.data;
		if (!result.error) {
			$scope.user = result;
			if (result.offsetY)
				$scope.offsetY = result.offsetY;


		}
		$scope.isLoading = false;
	});

	$scope.category = $location.search().mode || 'home';
	$scope.favoriteType = $location.search().favoriteType || false;

	setLimitNum();
	
	
	$scope.switchCategory = function (category) {
		$scope.category = category;
		$scope.favoriteType = $location.search().favoriteType || false;
		
		$location.search('mode', category);
		$location.search('favoriteType', undefined);

		setLimitNum();
	};

	// banner 拖曳位置 
	$rootScope.$on('draggableBg:repositioned', function (event, args) {
		console.log($scope.user.thumbnail, args);
		var y = Math.round(args.y * 100) / 100;
		$scope.offsetY = y;
	});

	$scope.saveBannerPosition = function () {
		$scope.bannerDragMode = false;
		UserFactory.setOffsetY($scope.user._id, $scope.offsetY).then(function (data) {
			console.log(data);
		});
	};

	$scope.startBannerDragMode = function () {
		$scope.bannerDragMode = true;
	};

	$scope.init = function () {
		//檢查是否為自己
		UserFactory.getUser().then(function (data) {
			if (data) {
				$scope.oneselfId = data._id;
			}
			else {
				$scope.isAuthor = false;
			}
			if ($scope.oneselfId == $scope.userId) {
				$scope.isAuthor = true;
			}
			//開啟編輯模式

		});
		//預設模式
		// $scope.isProjectMode = true;
		var mode = $location.search().mode || 'isProjectMode';
		$scope[mode] = true;

		//專案模式
		$scope.projects = [];
		ProjectFactory.getAuthorProjects($scope.userId).then(function (data) {
			$scope.projects = data;
		});
		//課程
		$scope.courses = [];
		CourseFactory.getAuthorCourses($scope.userId).then(function (data) {
			$scope.courses = data;
		});

		//正在關注
		$scope.focuses = [];
		UserFactory.listFocus($scope.userId).then(function (data) {
			data.data.forEach(function (data, i) {
				if (data.member._id)
					UserFactory.info(data.member._id).then(function (user) {
						var author = {};
						author.thumbnail = data.member.avatar;
						author._id = data.member._id;
						author.name = data.member.name;
						author.followCount = data.member.followCount;
						author.name = data.member.name;
						author.isFollow = user.data.isFollow;
						$scope.focuses.push(author);
					});
			});
		});

		//關注者（關注我的人）
		$scope.followers = [];
		UserFactory.listFollower($scope.userId).then(function (data) {
			// console.log(data);
			data.data.forEach(function (data, i) {
				if (data.follower._id)
					UserFactory.info(data.follower._id).then(function (user) {
						var author = {};
						// console.log(user);
						author.thumbnail = data.follower.avatar;
						author._id = data.follower._id;
						author.name = data.follower.name;
						author.followCount = data.follower.followCount;
						author.name = data.follower.name;
						author.isFollow = user.data.isFollow;

						$scope.followers.push(author);
					});


			});


		});

		//喜歡的專案 
		$scope.favoriteProjects = [];
		ProjectFactory.listLikeProjects($scope.userId).then(function (data) {
			$scope.favoriteProjects = data;
		});
		
		//喜歡的組織專案
		$scope.favoriteSealedProjects = [];
		SealedProjectFactory.listLikeProjects($scope.userId).then(function(data) {
			console.log(data);
			$scope.favoriteSealedProjects = data;
		});

		//喜歡的課程
		$scope.favoriteCourses = [];
		CourseFactory.listLikeCourses($scope.userId).then(function (data) {
			$scope.favoriteCourses = data;
		});
		
		//喜歡的組織課程
		$scope.favoriteSealedCourses = [];
		SealedCourseFactory.listLikeCourses($scope.userId).then(function(data) {
			$scope.favoriteSealedCourses = data;
		});
	};


	$scope.expandBio = function () {
		$scope.isExpand = !$scope.isExpand;
	};

	$scope.follow = function () {
		UserFactory.follow($scope.user._id).then(function (data) {
			var result = data.data;
			if (!result.error) {
				$scope.user.isFollow = true;
			}
		});
	};

	$scope.unfollow = function () {
		UserFactory.unfollow($scope.user._id).then(function (data) {
			var result = data.data;
			if (!result.error) {
				$scope.user.isFollow = false;
			}
		});
	};


	$scope.followUser = function (user, id) {
		UserFactory.follow(id).then(function (data) {
			var result = data.data;
			if (!result.error) {
				user.isFollow = !user.isFollow;
			}
		});
	};

	$scope.unfollowUser = function (user, id) {
		UserFactory.unfollow(id).then(function (data) {
			var result = data.data;
			if (!result.error) {
				user.isFollow = !user.isFollow;
			}
		});
	};

	$scope.uploadAvatar = function ($file) {
		$scope.uploadingAvatar = true;
		var upload = UserFactory.uploadAvatar($file);
		upload.then(function (resp) {
			if (resp.data) {
				$scope.user.avatar = resp.data.avatar;
				UserFactory.user.avatar = $scope.user.avatar;
			}
			$scope.uploadingAvatar = false;
		}, function (resp) {
			$scope.uploadingAvatar = false;
		}, function (evt) {
			$scope.progress = parseInt(100.0 * evt.loaded / evt.total);
			$scope.fileName = evt.config.data.file.name;
			console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
		});
	};

	$scope.uploadBanner = function ($file) {
		$scope.uploadingBanner = true;
		var upload = UserFactory.uploadBanner($file);
		upload.then(function (resp) {
			if (resp.data) {
				$scope.user.thumbnail = resp.data.thumbnail;
				UserFactory.user.thumbnail = $scope.user.thumbnail;
			}
			$scope.uploadingBanner = false;
		}, function (resp) {
			$scope.uploadingBanner = false;
		}, function (evt) {
			$scope.progress = parseInt(100.0 * evt.loaded / evt.total);
			$scope.fileName = evt.config.data.file.name;
			console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
		});
	};


});
