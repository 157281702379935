/**
 * Created by Tinny Wang on 2017/9/2.
 */

app.controller('AnalyticsController', function ($rootScope, $scope, $routeParams, $timeout, $window,
												NavFactory, ManageAnalyticsFactory) {

	NavFactory.setScope($scope);

	$scope.$on("NAV_SUCCESS", function (e, params) {
		$scope.navType = params.navType || $rootScope.navType;
		$scope.subType = params.subType || $rootScope.subType;
		_init(params.scope, $scope.navType, $scope.subType);
	});

	function promptError(msg) {
		$rootScope.promptMsg({
			msg: msg,
			icon: 'zmdi-alert-circle-o',
			iconColor: '#ff5075'
		});
	}

	function _init(scope, navType, subType) {
		$scope.navScope = scope;
		// $scope.ManageAnalytics = ManageAnalyticsFactory[navType][subType];
		$scope.ManageAnalytics = ManageAnalyticsFactory[navType];
		$scope.ManageAnalytics = $scope.ManageAnalytics[subType] || $scope.ManageAnalytics;

		$scope.activeRow = null;
		$scope.modal = {};
		$scope.formItems = [];
		$scope.formParam = {};

		$scope.overview = [];
		// scope.chartInfos = [];
		scope.chartInfos = {};
		$scope.tableTitle = null;

		initData(scope);

		var display = $scope.ManageAnalytics.display;
		if (display) {
			$scope.$parent.isSubSearch = !!(display.search);
			$scope.$parent.isAddNew = !!(display.addBtn);
			$scope.$parent.searchPlaceholder = display.search && display.search.placeholder;
			$scope.$parent.addNewName = display.addBtn && display.addBtn.name;

			$scope.tableTitle = display.tableTitle;
		} else {
			$scope.$parent.isSubSearch = false;
			$scope.$parent.isAddNew = false;
			$scope.$parent.searchPlaceholder = null;
			$scope.$parent.addNewName = null;
		}
	}

	function initData(scope) {
		if (!$scope.ManageAnalytics.getFormParam) {
			$scope.formParam = {};
			useData(scope);
			return;
		}
		var promise = $scope.ManageAnalytics.getFormParam(scope, {
			publisherId: $rootScope.publisherId
		});
		promise.then(function (resp) {
			if (resp.success) {
				$scope.formParam = resp.data;
				useData(scope);
			}
		});
	}

	function useData(scope, refresh) {
		getOverview(scope);
		drawChart(scope);

		var multiTables = $scope.ManageAnalytics.multiTables;
		if (multiTables) {
			multiTables.forEach(function (dataOptObj, idx) {
				_useData(scope, dataOptObj, idx, refresh);
			});
		} else {
			_useData(scope, $scope.ManageAnalytics, 0, refresh);
		}
	}

	function _useData(scope, dataOptObj, idx, refresh) {
		if (!dataOptObj.getData) {
			return;
		}
		var promise = dataOptObj.getData(scope, {
			publisherId: $rootScope.publisherId,
			formParam: $scope.formParam
		});
		promise.then(function (resp) {
			var data = null;
			if (resp.success) {
				data = resp.data;
				scope.tableInfos[idx].tableData = data.results;
				if (refresh) {
					$scope.$broadcast('TABLE_RESET', {
						data: scope.tableInfos
					});
				}
			}
		});
	}

	function getOverview(scope) {
		if (!$scope.ManageAnalytics.getOverview) {
			$scope.overview = [];
			return;
		}
		var promise = $scope.ManageAnalytics.getOverview(scope, {
			publisherId: $rootScope.publisherId,
			formParam: $scope.formParam
		});
		promise.then(function (resp) {
			var data = null;
			if (resp.success) {
				data = resp.data;
				$scope.overview = data;
			}
		});
	}

	function drawChart(scope) {
		var charts = $scope.ManageAnalytics.charts;
		if (charts) {
			for (var i = 0; i < charts.length; i++) {
				var chart = charts[i],
					chartId = chart.type + '_' + i,
					getChartData = chart.getChartData;

				// scope.chartInfos.push({
				// 	name: chart.name,
				// 	type: chart.type,
				// 	id: chartId,
				// 	config: chart.config
				// });

				scope.chartInfos[chartId] = {
					name: chart.name,
					type: chart.type,
					id: chartId,
					config: chart.config
				};

				if (getChartData) {
					var promise = getChartData(scope, {
						publisherId: $scope.publisherId,
						chartId: chartId
					});
					promise.then(function (resp) {
						if (!resp.success) {
							promptError(resp.msg);
							return;
						}
						var data = resp.data,
							params = resp.params,
							dataLen = data.length;
						for (var seriesId = 0; seriesId < dataLen; seriesId++) {
							updateChartConfig(scope, params.chartId, seriesId, resp.data[seriesId], params);
							// setChartData(scope, params.chartId, seriesId, resp.data[seriesId]);
						}
					});
				}
			}

			$timeout(function () {
				window.dispatchEvent(new Event('resize'));
			}, 10);
		}

	}

	function setChartData(scope, chartId, seriesId, data) {
		scope.$broadcast('CHART_SET_DATA_' + chartId, {
			seriesId: seriesId,
			data: data
		});
	}

	function updateChartConfig(scope, chartId, seriesId, data, params) {
		params = angular.extend({
			seriesId: seriesId,
			data: data
		}, params);
		scope.$broadcast('CHART_UPDATE_' + chartId, params);
	}

});

app.factory('ManageAnalyticsFactory', function ($q, $http, OrgProjectFactory, OrgCourseFactory, OrgMemberFactory, OrgCategoryFactory) {
	var _factory = {};

	function dealingResp(resp, deferred, callback) {
		if (resp.status == 200) {
			var data = resp.data;
			if (data.success) {
				data = callback(data) || data;
			}
			data = angular.extend({
				params: resp.params
			}, data);
			deferred.resolve(data);
		} else {
			deferred.reject('Failed!');
		}
	}

	function dealingRespWithParam(params, resp, deferred, callback) {
		resp = {
			status: resp.status,
			data: resp.data,
			params: params
		};
		dealingResp(resp, deferred, callback);
	}

	function secToTime(sec, isAutoFill) {
		var sec_num = parseInt(sec, 10),
			hours = Math.floor(sec_num / 3600),
			minutes = Math.floor((sec_num - (hours * 3600)) / 60),
			seconds = sec_num - (hours * 3600) - (minutes * 60);
		var time = [hours, minutes, seconds];
		if (isAutoFill) {
			time.forEach(function (t, idx) {
				if (t < 10) {
					time[idx] = '0' + t;
				}
			});
		} else {
			time.forEach(function (t, idx) {
				if (t > 0) {
					return;
				}
				time.shift();
			});
		}

		return time.join(':');
	}

	function calcBar(data, propName) {
		var results = data.data.results,
			maxNum = _.maxBy(results, propName)[propName];
		results.forEach(function (result, idx) {
			result.bar = result[propName] / maxNum;
			data.data.results[idx] = result;
		});
	}
	
	function getDateUTC(datetimeStr) {
		var datetime = new Date(datetimeStr);
		return Date.UTC(
			datetime.getFullYear(),
			datetime.getMonth(),
			datetime.getDate(),
			datetime.getHours(),
			datetime.getMinutes(),
			datetime.getSeconds()
		);
	}

	_factory.overview = {
		getOverview: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.memberStat(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					var counts = data.data;
					data.data = [{
						val: counts.allMemberCounts,
						description: '累積會員數'
					}, {
						val: counts.newMemberCounts,
						description: '新會員數'
					}, {
						val: counts.allFollowerCounts,
						description: '累計關注數'
					}, {
						val: counts.newFollowerCounts,
						description: '新關注數'
					}];
				});
			});
			return deferred.promise;
		},
		multiTables: [
			{
				getData: function (scope, params) {
					var deferred = $q.defer();
					OrgCategoryFactory.statList(params.publisherId, {}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {
							calcBar(data, 'viewCount');
						});
					});
					return deferred.promise;
				}
			},
			{
				getData: function (scope, params) {
					var deferred = $q.defer();
					OrgProjectFactory.statList(params.publisherId, {
						sort_type: '-viewCount',
						page: 1
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {

						});
					});
					return deferred.promise;
				}
			},
			{
				getData: function (scope, params) {
					var deferred = $q.defer();
					OrgCourseFactory.statList(params.publisherId, {
						sort_type: '-viewCount',
						page: 1
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {

						});
					});
					return deferred.promise;
				}
			}
		],
		charts: [{
			name: '使用時間分佈',
			type: 'line',
			config: {
				chart: {
					type: 'spline'
				},
				title: {
					text: ''
				},
				// subtitle: {
				// 	text: '使用時間分佈'
				// },
				xAxis: {
					type: 'datetime',
					gridLineWidth: 1,
					labels: {
						overflow: 'justify',
						format: '{value:%Y-%m-%d}'
					}
				},
				yAxis: {
					title: {
						text: ''
					},
					minorGridLineWidth: 0,
					gridLineWidth: 1,
					alternateGridColor: null
				},
				tooltip: {
					valueSuffix: ' 人'
				},
				plotOptions: {
					spline: {
						lineWidth: 2.5,
						states: {
							hover: {
								lineWidth: 3.5
							}
						},
						marker: {
							enabled: false
						},
						pointIntervalUnit: 'day',
						// pointInterval: 3600000, // one hour
						// pointStart: Date.UTC(2015, 4, 31, 0, 0, 0)
					}
				},
				data: {
					dateFormat: 'YYYY-mm-dd'
				},
				series: [{
					name: '訂閱總數',
					color: '#3FB47D'
				}, {
					name: '新訂閱數',
					color: '#14A5FF'
				}, {
					name: '關注總數',
					color: '#ff7200'
				}, {
					name: '新關注數',
					color: '#ffb100'
				}],
				navigation: {
					menuItemStyle: {
						fontSize: '10px'
					}
				}
			},
			getChartData: function (scope, params) {
				var deferred = $q.defer();
				OrgMemberFactory.memberStatTime(params.publisherId, {}).then(function (resp) {
					dealingRespWithParam(params, resp, deferred, function (data) {
						var counts = data.data,
							startDate = counts.startDate;
						data.data = [
							counts.allMemberCounts, counts.newMemberCounts,
							counts.allFollowerCounts, counts.newFollowerCounts
						];
						data.params = angular.extend({}, params, {
							pointStart: getDateUTC(startDate)
						});
					});
				});
				return deferred.promise;
			}
		}]
	};

	_factory.user = {};
	_factory.user.overview = {
		getOverview: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.memberStat(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					var counts = data.data;
					data.data = [{
						val: counts.allMemberCounts,
						description: '累積會員數'
					}, {
						val: counts.newMemberCounts,
						description: '新會員數'
					}, {
						val: counts.allFollowerCounts,
						description: '累計關注數'
					}, {
						val: counts.newFollowerCounts,
						description: '新關注數'
					}];
				});
			});
			return deferred.promise;
		},
		charts: [{
			name: '使用時間分佈',
			type: 'line',
			config: {
				chart: {
					type: 'spline'
				},
				title: {
					text: ''
				},
				// subtitle: {
				// 	text: '使用時間分佈'
				// },
				xAxis: {
					type: 'datetime',
					gridLineWidth: 1,
					labels: {
						overflow: 'justify',
						format: '{value:%Y-%m-%d}'
					}
				},
				yAxis: {
					title: {
						text: ''
					},
					minorGridLineWidth: 0,
					gridLineWidth: 1,
					alternateGridColor: null
				},
				tooltip: {
					valueSuffix: ' 人'
				},
				plotOptions: {
					spline: {
						lineWidth: 2.5,
						states: {
							hover: {
								lineWidth: 3.5
							}
						},
						marker: {
							enabled: false
						},
						pointIntervalUnit: 'day',
						// pointInterval: 3600000, // one hour
						// pointStart: Date.UTC(2015, 4, 31, 0, 0, 0)
					}
				},
				data: {
					dateFormat: 'YYYY-mm-dd'
				},
				series: [{
					name: '訂閱總數',
					color: '#3FB47D'
				}, {
					name: '新訂閱數',
					color: '#14A5FF'
				}, {
					name: '關注總數',
					color: '#ff7200'
				}, {
					name: '新關注數',
					color: '#ffb100'
				}],
				navigation: {
					menuItemStyle: {
						fontSize: '10px'
					}
				}
			},
			getChartData: function (scope, params) {
				var deferred = $q.defer();
				OrgMemberFactory.memberStatTime(params.publisherId, {}).then(function (resp) {
					dealingRespWithParam(params, resp, deferred, function (data) {
						var counts = data.data,
							startDate = counts.startDate;
						data.data = [
							counts.allMemberCounts, counts.newMemberCounts,
							counts.allFollowerCounts, counts.newFollowerCounts
						];
						data.params = angular.extend({}, params, {
							pointStart: getDateUTC(startDate)
						});
					});
				});
				return deferred.promise;
			}
		}]
	};
	_factory.user.user = {
		getOverview: function (scope, params) {
			var deferred = $q.defer();
			$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					data = [{
						val: 2731,
						description: '累積訂閱數'
					}, {
						val: 282,
						description: '新訂閱數'
					}, {
						val: 3283,
						description: '累計關注數'
					}, {
						val: 76,
						description: '新關注數'
					}, {
						val: '01:23',
						description: '平均使用時間'
					}];

					return data;
				});
			});
			return deferred.promise;
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgCategoryFactory.statList(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					calcBar(data, 'viewCount');
				});
			});
			return deferred.promise;
		},
		charts: [
			{
				name: '性別比例',
				type: 'pie',
				config: {
					chart: {
						width: 225,
						margin: 0,
						plotBackgroundColor: null,
						plotBorderWidth: 0,
						plotShadow: false
					},
					title: {
						text: ' ',
						align: 'center',
						verticalAlign: 'middle',
						y: -15
					},
					tooltip: {
						pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
					},
					plotOptions: {
						pie: {
							dataLabels: {
								enabled: true,
								borderWidth: 0,
								distance: -25,
								style: {
									color: 'white',
									fontSize: '14px',
									textOutline: 0
								}
							}
						}
					},
					series: [{
						type: 'pie',
						name: 'Sex ratio',
						innerSize: '55%',
						data: []
					}]
				},
				getChartData: function (scope, params) {
					var deferred = $q.defer();
					OrgMemberFactory.memberRatio(params.publisherId, {}).then(function (resp) {
						dealingRespWithParam(params, resp, deferred, function (data) {
							var genderCounts = data.data.genderCounts;
							var chartData = [[{
								name: '男性',
								y: genderCounts.male,
								color: '#03A9F4'
							}, {
								name: '女性',
								y: genderCounts.female,
								color: '#E91E63'
							}, {
								name: '未知',
								y: genderCounts.other,
								color: '#AFAFAF'
							}]];
							return {
								success: data.success,
								data: chartData
							};
						});
					});
					return deferred.promise;
				}
			},
			{
				name: '校區分佈比例',
				type: 'pie',
				config: {
					chart: {
						width: 225,
						margin: 0,
						plotBackgroundColor: null,
						plotBorderWidth: 0,
						plotShadow: false
					},
					title: {
						text: ' ',
						align: 'center',
						verticalAlign: 'middle',
						y: -15
					},
					tooltip: {
						pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
					},
					plotOptions: {
						pie: {
							dataLabels: {
								enabled: true,
								borderWidth: 0,
								distance: -25,
								style: {
									color: 'white',
									fontSize: '14px',
									textOutline: 0
								}
							}
						}
					},
					series: [{
						type: 'pie',
						name: 'Area ratio',
						innerSize: '55%',
						data: []
					}]
				},
				getChartData: function (scope, params) {
					var deferred = $q.defer();

					OrgMemberFactory.memberRatio(params.publisherId, {}).then(function (resp) {
						dealingRespWithParam(params, resp, deferred, function (data) {
							var locationCounts = data.data.locationCounts;
							var chartData = [[{
								name: '北',
								y: locationCounts.North,
								color: '#3F51B5'
							}, {
								name: '中',
								y: locationCounts.Center,
								color: '#03A9F4'
							}, {
								name: '南',
								y: locationCounts.South,
								color: '#00BCD4'
							}, {
								name: '東',
								y: locationCounts.East,
								color: '#20d486'
							}]];
							return {
								success: data.success,
								data: chartData
							};
						});
					});
					return deferred.promise;
				}
			}]
	};
	_factory.user.interest = {
		multiTables: [
			{
				getData: function (scope, params) {
					var deferred = $q.defer();
					OrgProjectFactory.statList(params.publisherId, {
						sort_type: '-likeCount',
						page: 1
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {

						});
					});
					return deferred.promise;
				}
			},
			{
				getData: function (scope, params) {
					var deferred = $q.defer();
					OrgProjectFactory.statList(params.publisherId, {
						sort_type: '-cloneCount',
						page: 1
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {

						});
					});
					return deferred.promise;
				}
			},
			{
				getData: function (scope, params) {
					var deferred = $q.defer();
					OrgCourseFactory.statList(params.publisherId, {
						sort_type: '-likeCount',
						page: 1
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {

						});
					});
					return deferred.promise;
				}
			},
			{
				getData: function (scope, params) {
					var deferred = $q.defer();
					OrgCourseFactory.statList(params.publisherId, {
						sort_type: '-cloneCount',
						page: 1
					}).then(function (resp) {
						dealingResp(resp, deferred, function (data) {

						});
					});
					return deferred.promise;
				}
			}
		],
	};

	_factory.content = {};
	_factory.content.project = {
		display: {
			tableTitle: '全部專案'
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgProjectFactory.statList(params.publisherId, {
				sort_type: '-viewCount',
				page: 1
			}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		getOverview: function (scope, params) {
			var deferred = $q.defer();
			OrgProjectFactory.stat(params.publisherId).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					var counts = data.data;
					data = {
						success: data.success,
						data: [{
							val: counts.allCounts,
							description: '專案總數'
						}, {
							val: counts.publicCounts,
							description: '公開專案總數'
						}, {
							val: counts.protectedCounts,
							description: '保護專案總數'
						}, {
							val: counts.newCounts,
							description: '新增專案數'
						}, {
							val: parseInt(counts.avgHitCounts),
							description: '平均點擊數'
							// description: '公開專案點閱率'
						}, {
							val: counts.totalCloneCounts,
							description: '複製總數'
						}, {
							val: secToTime(counts.totalReadTime),
							description: '總閱讀時間'
						}]
					};
					return data;
				});
			});
			return deferred.promise;
		}
	};
	_factory.content.course = {
		display: {
			tableTitle: '全部課程'
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgCourseFactory.statList(params.publisherId, {
				sort_type: '-viewCount',
				page: 1
			}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		getOverview: function (scope, params) {
			var deferred = $q.defer();
			OrgCourseFactory.stat(params.publisherId).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					var counts = data.data;
					data = {
						success: data.success,
						data: [{
							val: counts.allCounts,
							description: '課程總數'
						}, {
							val: counts.publicCounts,
							description: '公開課程總數'
						}, {
							val: counts.protectedCounts,
							description: '保護課程總數'
						}, {
							val: counts.newCounts,
							description: '新增課程數'
						}, {
							val: parseInt(counts.avgHitCounts),
							description: '平均點擊數'
							// description: '公開課程點閱率'
						}, {
							val: counts.totalCloneCounts,
							description: '複製總數'
						}, {
							val: secToTime(counts.totalReadTime),
							description: '總閱讀時間'
						}]
					};
					return data;
				});
			});
			return deferred.promise;
		}
	};
	_factory.content.author = {
		display: {
			tableTitle: '全部作者'
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.authorStatList(params.publisherId, {
				sort_type: '-projectCounts',
				page: 1
			}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {

				});
			});
			return deferred.promise;
		},
		getOverview: function (scope, params) {
			var deferred = $q.defer();
			OrgMemberFactory.authorStat(params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					var counts = data.data;
					data.data = [{
						val: counts.allCounts,
						description: '作者總數'
					}, {
						val: counts.newCounts,
						description: '新增作者'
					}];
				});
			});
			return deferred.promise;
		}
	};
	_factory.content.ad = {
		display: {
			tableTitle: '全部廣告'
		},
		getData: function (scope, params) {
			var deferred = $q.defer();
			$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					data.data = [{
						name: '課程多元化',
						exposureNum: 240,
						clickNum: 60,
						clickRate: 0.250
					}, {
						name: '跨領域思維',
						exposureNum: 931,
						clickNum: 572,
						clickRate: 0.614
					}];
				});
			});
			return deferred.promise;
		},
		getOverview: function (scope, params) {
			var deferred = $q.defer();
			$http.get('/api/temp', params.publisherId, {}).then(function (resp) {
				dealingResp(resp, deferred, function (data) {
					data.data = [{
						val: '47%',
						description: '平均點擊率（時間）'
					}, {
						val: 1345,
						description: '廣告曝光數'
					}, {
						val: 64,
						description: '廣告點擊率'
					}];
				});
			});
			return deferred.promise;
		}
	};

	return _factory;
});
