app.controller("OrgCourseTicketController", function($q, $scope, $rootScope, $http, $timeout, $location, UserFactory, ProjectFactory, CourseFactory, RedeemFactory, CategoryFactory) {

    $scope.courseId = $("#course").attr("course-id");
    $scope.project = {};
    $scope.isLiked = false;
    $scope.isLoading = true;
    $scope.isOpenSidebar = true;
    $scope.cloneSuccess = false;
    $scope.isFollow = false;
    $scope.isFullScreenMode = false;
    $scope.chapters = [];
    $scope.origin = location.origin;
    $scope.user = undefined;
    $scope.isExpireTime = false;
    $scope.isExpired = false;
    $scope.isRedeemEvent = false;
    $scope.isShowExpiredHint = false;

    //-- reddem相關 課程屏蔽
    $scope.redeemHide = true;
    $scope.isRedeemed = false;


    function loadAuthor(userId) {
        UserFactory.info(userId).then(function(data) {
            if (!data.data.error) {
                $scope.author = data.data;
            }
        });
    };

    function loadRedeem(courseId) {
        RedeemFactory.checkRedeem(courseId).then(function(data) {
            if (!data.error) {
                $scope.isRedeemed = true;
                if ($scope.isRedeemed) {
                    $scope.redeemHide = false;
                }
            } else {
                $scope.isRedeemed = false;
            }
        });
    };

    function checkRedeemState(courseId) {
        RedeemFactory.checkRedeemState($scope.courseId).then(function(data) {

            if (!data.error && data.expireAt) {
                // console.log(data)
                //有兌換活動
                $scope.isRedeemEvent = true;
                $scope.isExpireTime = true;
                $scope.expireAt = data.expireAt;

                var date = new Date();
                var now = date.getTime();
                var expireAt = new Date(data.expireAt);
                var event = expireAt.getTime();

                if (event - now > 0) {
                    $scope.isExpired = false;
                } else {
                    $scope.isExpired = true;
                }


                $scope.redeemHide = true;
            } else {
                $scope.isRedeemEvent = false;
                //課程不用屏蔽
                if( $scope.user){
                    $scope.redeemHide = false;
                }
                else{
                    $scope.redeemHide = true;
                }
            }
        });
    }

    function RedeemInit(courseId) {
		CourseFactory.info(courseId).then(function (resp) {
			if(resp.data.isShared != true){
				//時間獲取
				checkRedeemState(courseId);
				//是否兌換獲取
				loadRedeem(courseId);
				// 如果是開放 //false
				$scope.redeemHide = true;
			}

		}, function (error) {

		});
    }

	$scope.UserFactory = UserFactory;

    $scope.exchangeCourse = function() {
        if (UserFactory.user._id) {
            $rootScope.$broadcast("EXCHANGE_COURSE", {
                courseId: $scope.courseId,
                expireAt: $scope.expireAt,
                callback: function(result) {
                    if (result) {
                        //兌換成功
                        $scope.redeemHide = false;
                        $scope.isRedeemed = true;
                    }
                }
            });
        }
    }

    $scope.showRedeemHint = function() {

        if (!$scope.isExpired) {
            $scope.isShowRedeemHint = true;
            $timeout(function() {
                $scope.exchangeCourse();
                $scope.isShowRedeemHint = false;
            }, 2000);
        } else {
            $scope.isShowExpiredHint = true;
            $timeout(function() {
                $scope.isShowExpiredHint = false;
            }, 2000);

        }
    }

    $scope.init = function() {

        UserFactory.getUser().then(function(user) {
            if (user._id) {
                $scope.user = user;
                $scope.author = loadAuthor(user._id);
            } else {
                $scope.user = undefined;
            }
        });

		var unwatch = $scope.$watch('UserFactory.user', function(newValue, oldValue) {
			if(newValue){
				var UserID = newValue._id;
				// console.log('UserID: ' + UserID);
				dataLayer.push({'UserID': UserID});
			}
		});

        RedeemInit($scope.courseId);
    };
});
