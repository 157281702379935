/**
 * Created by Tinny Wang on 2017/8/10.
 */

app.controller('SearchController', function ($scope, UserFactory) {

	$scope.nameMap = {
		project: '專案',
		course: '課程',
		member: '作者',
		channel: '頻道'
	};
	
	$scope.category = 'project';
	$scope.categoryName = $scope.nameMap[$scope.category];
    
	$scope.changeCategory = function (category, categoryName) {
		$scope.category = category;
		$scope.categoryName = categoryName;
	};

	$scope.followUser = function(user, id) {
		UserFactory.follow(id).then(function(data) {
			var result = data.data;
			if (!result.error) {
				user.isFollow = !user.isFollow;
			}
		});
	};

	$scope.unfollowUser = function(user, id) {
		UserFactory.unfollow(id).then(function(data) {
			var result = data.data;
			if (!result.error) {
				user.isFollow = !user.isFollow;
			}
		});
	};
});
