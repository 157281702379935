app.controller("ResetPwdController", function($scope, $rootScope, $location, $timeout, UserFactory) {

	var urlParams = $location.search();
    $scope.token = urlParams.token;
    $scope.email = urlParams.email;
	$scope.password;
	$scope.repassword;
	$scope.isSaving = false;
	$scope.passwordError = false;
	$scope.resetError = false;

	$scope.reset = function () {

		if ($scope.password != $scope.repassword) {
			$scope.passwordError = true;
			return;			
		}

		$scope.isSaving = true;
		$scope.passwordError = false;

		UserFactory.resetpwd($scope.email, $scope.password, $scope.token).then(function (data) {
			$scope.isSaving = false;
			var result = data.data;
			if (result.error) {
				$scope.resetError = true;
				return;
			}
			$scope.resetError = false;
			$scope.isSuccess = true;
		});
	};
});