app.controller("MyCourseController", function ($rootScope, $scope, UserFactory, CourseFactory) {

	$scope.maxCourse = 0;
	$scope.numberOfCourse = {};

	UserFactory.getUser().then(function (data) {
		if (data._id) {
			$scope.maxCourse = data.courseMax;
		}
	});

	CourseFactory.countCourses().then(function (data) {
		if (data.data) {
			$scope.numberOfCourse = data.data;
		}
	});

	$scope.$on('NEW_ITEM', function (e, params) {
		$rootScope.$broadcast("NEW_COURSE", {
			callback: function (item) {
				if (item) {
					$scope.numberOfCourse.allCourses++;
					params.callback(item);
				}
			}
		});
	});

	$scope.applyMoreCourse = function () {
		$rootScope.$broadcast("APPLY_MORE_COURSE", {
			callback: function (result, data) {

			}
		});
	};
	
});
