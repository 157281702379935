app.controller("RemoveProjectController", function ($scope, $ngModal, MyProjectFactory, CategoryFactory) {

	$scope.isRemoving = false;

	$scope.$on("REMOVE_PROJECT", function (e, params) {
		$scope.callback = params.callback;
		$scope.project = params.project;
		$ngModal.openModal("remove-project-modal");
		Mousetrap.bind('enter', function(e) {
            $scope.$apply(function() {
            	$scope.remove();
            })
        });
	});

	function close () {
		$scope.project = undefined;
		$scope.isRemoving = false;
		$ngModal.closeModal("remove-project-modal");
		Mousetrap.unbind('enter');
	};

	$scope.remove = function () {
		$scope.isRemoving = true;

		MyProjectFactory.remove({
			id: $scope.project._id
		}).then(function (data) {
			$scope.callback(data.data, $scope.project);
			close();
		});
	};
});
