/**
 * Created by Tinny Wang on 2017/12/7.
 */

app.controller('SendItemController', function ($scope, $timeout, $ngModal, InviteFactory, InviteCourseFactory, UserFactory) {
	$scope.email = "";
	$scope.name = "";
	$scope.isPublic = 'false';
	$scope.description = "";
	$scope.thumbnail;
	$scope.isSending = false;
	$scope.receivers = [];
	$scope.newReceivers = [];
	$scope.removeInvites = [];

	$scope.$on("SEND_ITEM", function (e, params) {
		$scope.callback = params.callback;
		$scope.item = params.item;
		$scope.receivers = [];
		$scope.newReceivers = [];
		$scope.removeInvites = [];
		listInvites();
		$ngModal.openModal("send-item-modal");
	});

	$scope.selectedObject = function (item) {
		// console.log(item);
		var email = item.description;
		addReceiver(email);
	};

	$scope.onKeyup = function (event) {
		var keyCode = event.keyCode;
		if (keyCode == 13) {
			if (is.email($scope.email)) {
				addReceiver($scope.email);
			}
		}
	};

	$scope.onPaste = function (event) {
		$timeout(function () {
			if (is.email($scope.email)) {
				addReceiver($scope.email);
			}
		}, 200);
	};

	$scope.remove = function (receiver) {
		var idx = $scope.newReceivers.indexOf(receiver);
		$scope.newReceivers.splice(idx, 1);
	};

	$scope.addToRemoves = function (receiver) {
		var idx = $scope.receivers.indexOf(receiver);
		$scope.removeInvites.push(receiver._id);
		$scope.receivers.splice(idx, 1);
	};

	function addReceiver(email) {
		UserFactory.infoByEmail(email).then(function (data) {
			var result = data.data;
			if (result.error) {
				// $scope.newReceivers.push({
				// 	name: email
				// });
			} else {
				console.log(result);
				$scope.newReceivers.push(result);
				$scope.email = "";
			}
		});
	}

	function close() {
		$scope.name = "";
		$scope.isPublic = false;
		$scope.description = "";
		$scope.thumbnail;
		$scope.isSending = false;
		$ngModal.closeModal("send-item-modal");
	}

	function listInvites() {
		InviteFactory.listInvites($scope.item._id).then(function (data) {
			if (!data.data.error) {
				$scope.receivers = data.data;
			}
		});
	}

	$scope.send = function (type) {
		$scope.isSending = true;
		var inviteFn = undefined;
		if(type == 'course'){
			inviteFn = InviteCourseFactory;
		}else if(type == 'project'){
			inviteFn = InviteFactory;
		}else{
			return;
		}
		inviteFn.send([$scope.item], $scope.newReceivers, $scope.removeInvites).then(function (data) {
			$scope.isSending = false;
			var result = data.data;
			if (result.error) {
				return;
			}
			close();
		});
	};
});
