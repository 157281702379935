/**
 * Created by Tinny Wang on 2017/12/8.
 */
app.controller('MyProjectsController', function ($rootScope, $scope, $ngModal, Upload) {
	var defaultMsg = '轉換中，若切換至其他畫面則會停止轉換';
	
	$scope.$on('NEW_ITEM', function (e, params) {
		$rootScope.$broadcast("NEW_PROJECT", {
			folderId: params.folderId,
			callback: params.callback
		});
	});

	$scope.$on('FINISH_UPLOAD_PPT', function (e, params) {
		$rootScope.$broadcast("ADD_ITEM", {
			item: params.item
		});
	});

	$scope.acceptMimeTypes = [
		'application/vnd.ms-powerpoint', // ppt
		'application/vnd.openxmlformats-officedocument.presentationml.presentation' // pptx
	];
	$scope.convertMsg = defaultMsg;
	$scope.uploadList = [];

	// 上傳 PPT
	$scope.uploadPPT = function ($files, type) {
		$ngModal.closeModal("convert-chosen-modal");

		var url = {
			normal: '/api/project/convert-ppt',
			images: '/api/project/convert-ppt-with-images'
		};

		var isFirst = true;
		$files.forEach(function ($file) {
			var item = {
				name: $file.name,
				progress: 0,
			};
			$scope.uploadList.push(item);
			var upload = Upload.upload({
				url: url[type],
				data: {file: $file},
			});
			var idx = $scope.uploadList.indexOf(item);
			upload.then(function (resp) {
				var result = resp.data,
					errMsg = result.error;
				$scope.uploadList.splice(idx, 1);
				if(errMsg) {
					popUploadPptMsg(errMsg);
				} else {
					$rootScope.$broadcast("ADD_ITEM", {
						item: result
					});
					// $rootScope.$broadcast("FINISH_UPLOAD_PPT", {
					// 	item: result
					// });
				}
			}, function (resp) {
				// console.log('>>> Upload failed.');
				// console.log(resp);
				var status = resp.status;

				// Temporary solution for timeout.
				if(status === 504) {
					$scope.uploadList.splice(idx, 1);
					popUploadPptMsg('簡報檔案較大，請隔段時間重整頁面，若轉換完成專案將顯示於清單中。');
				}
			}, function (evt) {
				if (isFirst) {
					// $rootScope.$broadcast("CONVERT_PPT");
					popUploadPptMsg(defaultMsg);
					isFirst = false;
				}
				item.progress = parseInt(100.0 * evt.loaded / evt.total);
				if (item.progress >= 90) {
					item.progress = 90;
				}
			});
			item.cancel = function () {
				var idx = $scope.uploadList.indexOf(item);
				$scope.uploadList.splice(idx, 1);
				upload.abort();
			};
		});
	};

	function popUploadPptMsg(msg) {
		$scope.convertMsg = msg;
		$ngModal.openModal("convert-ppt-modal");
	}
});
