app.controller("ForgetPwdController", function ($scope, $timeout, $http) {

    $scope.isSent = false;
    $scope.isSending = false;
    $scope.isFailure = false;

    $scope.send = function () {

        $scope.isEmailFailure = !is.email($scope.email);
        if ($scope.isEmailFailure) return;
        $scope.isFailure = false;
        $scope.isSending = true;
        $http({
            url: "/member/forgetpwd",
            method: "POST",
            data: $.param({
                email: $scope.email,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).success(function(data) {
            if (!data.error) {
                $scope.isFailure = false;
                $scope.isSending = false;
                $scope.isSent = true;
                $timeout(function () {
                    $scope.startAnimation = true;
                }, 100);
            }
            else {
                $scope.isFailure = true;
                $scope.reset();
            }
        }).error(function(data, status, headers, config) {
            $scope.reset();
        });
    };

    $scope.reset = function () {
        $scope.isSent = false;
        $scope.isSending = false;
        $scope.startAnimation = false;
    };
});