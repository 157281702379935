/**
 * Created by Dominic Lee on 2017/11/23.
 */

app.controller('OrgProjectInfoController', function ($rootScope, $scope, $timeout, $ngModal, UserFactory, SealedProjectFactory, ProjectFactory) {
	$scope.pageOver = false;
	$scope.projectId = $("#project").attr("project-id");
	$scope.project = {};
	$scope.isLiked = false;
	$scope.isLoading = true;
	$scope.cloneSuccess = false;
	$scope.isFollow = false;
	$scope.isFullScreenMode = false;
	$scope.checkIsOrignalProject = true;
	$scope.originAuthor = {};
	$scope.originProject;

	$scope.toast = {
		isOpen: false,
		color: undefined,
		msg: undefined,
		link: undefined,
	};

	function showToast(msg, link, color, duraction) {
		$scope.toast.msg = msg;
		$scope.toast.isOpen = true;
		$scope.toast.color = color;
		$scope.toast.link = link;
		$timeout(function () {
			$scope.toast.isOpen = false;
			$scope.toast.msg = undefined;
			$scope.toast.color = undefined;
			$scope.toast.link = undefined;
		}, duraction);
	};

	SealedProjectFactory.info($scope.projectId).then(function(data) {
		var result = data.data;
		if (!result.error) {
			$scope.project = result;
		}
		$scope.isLoading = false;
		loadAuthor($scope.project.author._id);
		getOriginProjectInfo();
	});


	function getOriginProjectInfo() {
		var authorFlag = false;
		if ($scope.project.originAuthor && $scope.project.originAuthor != $scope.project.author._id) {
			$scope.checkIsOrignalProject = false;
			UserFactory.info($scope.project.originAuthor).then(function(data) {
				if (!data.data.error) {
					$scope.originAuthor = data.data;
					authorFlag = true;
				}
			});
		}

		if ($scope.project.originProjectId && $scope.project.originProjectId != $scope.project._id) {
			$scope.checkIsOrignalProject = false;
			ProjectFactory.info($scope.project.originProjectId).then(function(data) {
				if (!data.data.error) {
					$scope.originProject = data.data;
					if (!authorFlag) {
						UserFactory.info(data.data.author._id).then(function(d) {
							if (!d.data.error) {
								$scope.originAuthor = d.data;
								console.log($scope.originAuthor);
							}
						});
					}
				}
			});
		} else {
			$scope.originProject = $scope.project;
		}

	}

	function loadAuthor(userId) {
		UserFactory.info(userId).then(function(data) {
			if (!data.data.error) {
				$scope.author = data.data;
			}
		});
	}

	$scope.like = function() {
		SealedProjectFactory.like($scope.project._id).then(function(data) {
			var result = data.data;
			if (!result.error) {
				$scope.project.isLiked = true;
				$scope.project.likeCount++;
			}
		});
	};

	$scope.unlike = function() {
		SealedProjectFactory.unlike($scope.project._id).then(function(data) {
			var result = data.data;
			if (!result.error) {
				$scope.project.isLiked = false;
				$scope.project.likeCount--;
			}
		});
	};

	// 複製專案
	$scope.cloneProject = function() {
		if (!UserFactory.user._id) return;
		$rootScope.$broadcast("CLONE_ITEM", {
			item: $scope.project,
			callback: function(result) {
				// 成功複製
				if(result.success) {
					showToast("複製成功", '/myprojects', 'blue', 2500);
					// TDAPP.onEvent("Sealed-Project-Clone", $scope.project._id);
				}
				else {
					showToast(result.msg, undefined, 'red', 2500);
				}
			}
		});
	};

});
