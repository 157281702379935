/**
 * Created by Tinny Wang on 2017/12/5.
 */

// Refactor from MyProjectController and MyCourseController
app.controller('MyItemController', function ($rootScope, $scope, $location, $timeout, Upload,
											 MyItemNavFactory, FolderFactory, MyProjectFactory) {
	$scope.isLoading = true;
	$scope.keyword = '';
	$scope.newFolderName = '';
	$scope.isCreateFolder = false;
	$scope.origin = DOMAIN.default;
	$scope.items = [];
	$scope.folders = [];
	$scope.uploadings = [];

	$scope.getNav = MyItemNavFactory.getNav;

	$scope.selected = [];
	$scope.orderBy = localStorage.getItem("MYITEM_ORDER") || "-lastModifiedAt";

	//TODO 待重構（../mixins/prompt/toast-prompt）
	$scope.toast = {
		message: "",
		isOpen: false
	};

	var isInit = false,
		loadItems = function () {
			return [];
		},
		inviteFactory = {},
		statusCodeInfo = {
			'-1': 'reject',
			'0': 'pending',
			'1': 'approve',
			'2': 'approve'
		};

	function init(mode, params) {
		loadFolders();
		loadItems(mode, params, function (items) {
			$scope.items = items;
			$scope.isLoading = false;
		});
	}

	function loadFolders(callback) {
		FolderFactory.getFolders().then(function (folders) {
			$scope.folders = folders;
			callback && callback(folders);
		});
	}

	function toggleItem(item) {
		var idx = $scope.selected.indexOf(item);
		if (idx === -1) {
			selectItem(item);
		}
		else {
			unSelectItem(item);
		}
	}

	function selectItem(item) {
		var idx = $scope.selected.indexOf(item);
		if (idx === -1) {
			item.isSelected = true;
			$scope.selected.push(item);
		}
	}

	function unSelectItem(item) {
		var idx = $scope.selected.indexOf(item);
		item.isSelected = false;
		$scope.selected.splice(idx, 1);
	}

	function clearSelected() {
		$scope.selected.forEach(function (item) {
			item.isSelected = false;
		});
		$scope.selected = [];
	}

	function createFolder(name) {
		FolderFactory.create({
			name: name
		}).then(function (result) {
			var folder = result.data;
			$scope.folders.push(folder);
			$scope.isCreateFolder = false;
			$scope.newFolderName = "";
		});
	}

	function renameFolder(folder) {
		FolderFactory.rename({
			id: folder._id,
			name: folder.newName
		}).then(function (result) {
			folder.name = folder.newName;
			delete folder.newName;
			folder.editing = false;
		});
	}

	function showToast(message, link, duraction) {
		$scope.toast.message = message;
		$scope.toast.isOpen = true;
		$scope.toast.link = link;
		$timeout(function () {
			$scope.toast.isOpen = false;
			$scope.toast.link = undefined;
		}, 2500);
	}

	function getItemIndex(curItem) {
		var itemIdx = undefined;
		angular.forEach($scope.items, function(item, index) {
			if(curItem['_id'] == item['_id']) {
				itemIdx = index;
			}
		});

		return itemIdx;
	}

	$scope.$on("$locationChangeSuccess", function () {
		var urlParam = $location.search(),
			mode = urlParam.mode;
		$scope.mode = mode || 'all';
		$scope.modeParam = urlParam[mode];

		if (!isInit){
			init($scope.mode, $location.search());
			isInit = true;
		}
	});

	$scope.$on('ADD_ITEM', function (e, params) {
		$scope.items.push(params.item);
	});

	$scope.$watch("orderBy", function (order, old) {
		if (order == old) return;
		localStorage.setItem("MYITEM_ORDER", order);
	});

	$scope.myItemInit = function (type, typeName, url) {
		MyItemNavFactory.setItemType(type, typeName, url);
		$scope.navInfos = MyItemNavFactory.navs;
		$scope.navs = MyItemNavFactory.getNavs(type);
		$scope.currentNav = $scope.navs[0];
		loadItems = MyItemNavFactory.getLoadItemsFn(type);
		inviteFactory = MyItemNavFactory.getInviteFactory(type);
	};

	$scope.isActive = function (mode, param) {
		var active = (mode == $scope.mode);
		if (param) {
			active = (param == $scope.modeParam);
		}
		return active;
	};

	$scope.reload = function (nav, mode) {
		$scope.items = [];
		$scope.currentNav = MyItemNavFactory.getNav(mode);
		$scope.currentNav.label = nav.label || nav.name;
		$scope.isLoading = true;
		loadItems(mode, nav, function (items) {
			$scope.items = items;
			$scope.isLoading = false;
		});
	};

	$scope.refresh = function() {
		var urlParam = $location.search(),
			mode = urlParam.mode;

		$scope.currentNav = MyItemNavFactory.getNav(mode);
		$scope.isLoading = true;
		loadItems(mode, urlParam, function (items) {
			$scope.items = items;
			$scope.isLoading = false;
		});
	};

	$scope.isStatus = function (status, orgItem) {
		if (!orgItem && !$scope.isActive('submit')){
			return false;
		}
		var statusCode = orgItem ? orgItem.status : '-1';
		return status == statusCodeInfo[statusCode + ''];
	};

	$scope.applyShare = function (event) {
		event.preventDefault();
		event.stopPropagation();
		$rootScope.$broadcast("SHARE_ITEM", {
			shareType: MyItemNavFactory.getItemType(),
			callback: function () {

			}
		});
	};

	$scope.selectItem = function (event, item) {
		if (event.metaKey) {
			event.preventDefault();
			event.stopPropagation();
			toggleItem(item);
		}
	};

	$scope.searchKeyword = function (keyword) {
		$scope.keyword = keyword;
	};

	// 關鍵字過濾
	$scope.searchFilter = function (item) {
		if (!$scope.keyword) {
			return true;
		}

		if (item) {
			var isMatch = false,
				keywords = $scope.keyword && $scope.keyword.split(" ");

			for (var i = 0; i < keywords.length; i++) {

				var word = keywords[i].toLowerCase();
				var keywords = item.keywords && item.keywords.toLowerCase() || "",
					name = item.name && item.name.toLowerCase() || "",
					description = item.description && item.description.toLowerCase() || "";

				if (keywords.indexOf(word) != -1 ||
					name.indexOf(word) != -1 ||
					description.indexOf(word) != -1) {
					isMatch = true;
				} else {
					isMatch = false;
					return false;
				}
			}

			return isMatch;
		}
		return false;
	};

	$scope.clearSelected = clearSelected;

	// 新增
	$scope.newItem = function () {
		$scope.$broadcast("NEW_ITEM", {
			folderId: $scope.modeParam,
			callback: function (item) {
				if (item) {
					$scope.items.push(item);
				}
			}
		});
	};

	// 移到垃圾桶
	$scope.moveToTrash = function (items) {
		var itemIds = items.map(function (item) {
			return item._id;
		});
		$rootScope.$broadcast("MOVE_TO_TRASH", {
			itemIds: itemIds,
			callback: function (result, item) {
				if (!result.error) {
					clearSelected();
					$scope.refresh();
				}
			}
		});
	};

	$scope.sendItem = function (item) {
		$rootScope.$broadcast("SEND_ITEM", {
			item: item,
			callback: function (result, item) {
			}
		});
	};

	$scope.acceptInvite = function (item) {
		inviteFactory.acceptInivte(item.inviteId).then(function () {
			var idx = $scope.items.indexOf(item);
			$scope.items.splice(idx);
			$location.search({});
			$scope.refresh();
		});
	};

	$scope.rejectInvite = function (item) {
		inviteFactory.rejectInivte(item.inviteId).then(function () {
			var idx = $scope.items.indexOf(item);
			$scope.items.splice(idx);
		});
	};

	// 刪除物件
	$scope.removeItem = function (item) {
		$rootScope.$broadcast("REMOVE_ITEM", {
			item: item,
			callback: function (result, item) {
				if (!result.error) {
					var idx = $scope.items.indexOf(item);
					$scope.items.splice(idx, 1);
				}
			}
		});
	};

	// 清空垃圾桶
	$scope.emptyTrash = function () {
		$rootScope.$broadcast("EMPTY_TRASH", {
			callback: function (result) {
				if (!result.error) {
					$scope.items.length = [];
				}
			}
		});
	};

	// 複製物件
	$scope.cloneItem = function (item) {
		$rootScope.$broadcast("CLONE_ITEM", {
			item: item,
			folderId: $scope.modeParam,
			callback: function (result) {
				$scope.items.unshift(result);
			}
		});
	};

	// 更新物件
	$scope.updateItem = function (item) {
		var itemType = MyItemNavFactory.getItemType();
		itemType = itemType.toUpperCase();
		$rootScope.$broadcast("UPDATE_" + itemType,
			item,
			function (item) {
				if (item) {
					$scope.items.forEach(function (p, i) {
						if (item._id == p._id) {
							$scope.items[i] = item;
						}
					});
				}
			});
	};

	// 移回物件
	$scope.restore = function (item) {
		var restoreFn = MyItemNavFactory.getRestoreFn();
		restoreFn({id: item._id}).then(function (result) {
			var idx = $scope.items.indexOf(item);
			$scope.items.splice(idx, 1);
		});
	};

	//TODO 待重構 ==============================================
	$scope.changePublic = function (item) {
		MyProjectFactory.update({
			id: item._id,
			name: item.name,
			description: item.description,
			thumbnail: item.thumbnail,
			isPublic: item.isPublic,
		}).then(function (data) {
		});
	};

	// 上傳 PPT
	$scope.uploadPPT = function ($files) {
		var isFirst = true;

		$files.forEach(function ($file) {
			var item = {
				name: $file.name,
				progress: 0,
			};
			$scope.uploadings.push(item);
			var upload = Upload.upload({
				url: 'api/project/covert-ppt',
				data: {file: $file},
			});
			upload.then(function (resp) {
				var result = resp.data;
				if (!result.error) {
					var idx = $scope.uploadings.indexOf(item);
					$scope.uploadings.splice(idx, 1);
					$scope.items.push(result);
				}
			}, function (resp) {
			}, function (evt) {

				if (isFirst) {
					$rootScope.$broadcast("CONVERT_PTT");
					isFirst = false;
				}

				item.progress = parseInt(100.0 * evt.loaded / evt.total);
				if (item.progress >= 90) {
					item.progress = 90;
				}
			});
			item.cancel = function () {
				var idx = $scope.uploadings.indexOf(item);
				$scope.uploadings.splice(idx, 1);
				upload.abort();
			};
		});
	};

	// 從資料夾中刪除
	$scope.removeFromFolder = function (item) {
		FolderFactory.removeProjects($scope.modeParam, [item._id]).then(function (data) {
			var result = data.data;
			if (!result.error) {
				var idx = $scope.items.indexOf(item);
				$scope.items.splice(idx, 1);
			}
		});
	};

	// 進入建立資料夾模式
	$scope.toggleCreateFolder = function () {
		$scope.isCreateFolder = !$scope.isCreateFolder;
		$rootScope.$broadcast("CREATE_FOLDER");
	};

	$scope.toggleFolderEditable = function (folder) {
		folder.editing = !folder.editing;
		folder.newName = folder.name;
		$rootScope.$broadcast("EDIT_FOLDER_" + folder._id);
	};

	// 資料夾輸入輸入
	$scope.onFolderKeyup = function (event, name) {
		var keyCode = event.keyCode;
		if (keyCode == 13 && name) {
			createFolder(name);
		}
		else if (keyCode == 27) {
			$scope.newFolderName = "";
			$scope.isCreateFolder = false;
		}
	};

	// 重新命名
	$scope.onEditFolderKeyup = function (event, folder) {
		var keyCode = event.keyCode;
		if (keyCode == 13 && folder.newName) {
			renameFolder(folder);
		}
		else if (keyCode == 27) {
			delete folder.newName;
			folder.editing = false;
		}
	};

	// 刪除專案
	$scope.removeFolder = function (folder) {
		$rootScope.$broadcast("REMOVE_FOLDER", {
			folder: folder,
			callback: function (result, folder) {
				if (!result.error) {
					var idx = $scope.folders.indexOf(folder),
						isCurrentFolder = folder._id == $scope.modeParam;
					$scope.folders.splice(idx, 1);
					if (isCurrentFolder) {
						$location.search({});
						$scope.refresh();
					}
				}
			}
		});
	};

	$scope.addProjects = function (folder, items) {
		var itemIds = items.map(function (p) {
				return p._id;
			}),
			params = {
				id: folder._id,
				itemIds: itemIds
			};
		FolderFactory.addProjects(params);
	};

	$scope.onDrop = function (event, ui, folder) {
		console.log(folder);
		var items = $scope.selected,
			itemIds = items.map(function (p) {
				return p._id;
			}),
			params = {
				id: folder._id,
				projectIds: itemIds.join()
			};

		FolderFactory.addProjects(params).then(function (result) {
			showToast("專案已添加至分類「" + folder.name + "」", "/myprojects?mode=folder&folder=" + folder._id);
			clearSelected();
		});
	};

	$scope.onTrashDrop = function (event, ui) {
		var items = $scope.selected;
		$scope.moveToTrash(items);
	};

	$scope.onDragStart = function (event, ui, curItem) {
		var index = getItemIndex(curItem);
		$scope.draggingItem = $scope.items[index];
		$scope.$apply(function () {
			if (!$scope.draggingItem.isSelected) {
				clearSelected();
			}
			selectItem($scope.draggingItem);
		});
	};

	//=======================================================

});


//TODO---待重構
app.factory('MyItemNavFactory', function (UserFactory, MyProjectFactory, FolderFactory,
										  MyCourseFactory, RedeemFactory, InviteFactory, InviteCourseFactory) {

	var tempLabel = '{LABEL}', tempUrl = '{URL}';
	var itemType = '', itemLabel = '', basedUrl = '';

	var itemNavs = {};
	itemNavs.all = {
		name: 'all',
		label: '所有' + tempLabel,
		icon: 'icon-grid',
		url: tempUrl + '?mode=all',
		emptyInfo: {
			img: '/images/project-empty.svg',
			title: '尚未建立' + tempLabel,
			text: '點擊右上角「＋ 新增' + tempLabel + '」按鈕建立全新' + tempLabel + '。'
		}
	};
	itemNavs.public = {
		name: 'public',
		label: '已公開' + tempLabel,
		icon: 'icon-share',
		url: tempUrl + '?mode=public',
		emptyInfo: {
			img: '/images/project-empty.svg',
			title: '尚未建立專案',
			text: '點擊右上角「＋ 新增' + tempLabel + '」按鈕建立全新' + tempLabel + '。'
		}
	};
	itemNavs.invite = {
		name: 'invite',
		label: tempLabel + '邀請',
		icon: 'icon-users',
		url: tempUrl + '?mode=invite',
		emptyInfo: {
			img: '/images/empty-invite.svg',
			title: '沒有收到邀請',
			text: '如果有其他使用者想要將' + tempLabel + '贈送給你，<br>你可以在這裡看到贈送的邀請。'
		}
	};
	itemNavs.submit = {
		name: 'submit',
		label: '投稿狀態',
		icon: 'icon-newspaper',
		url: tempUrl + '?mode=submit',
		emptyInfo: {
			img: '/images/project-empty.svg',
			title: '尚未投稿' + tempLabel,
			text: '點擊下方「分享我的' + tempLabel + '」按鈕投稿。'
		}
	};
	itemNavs.trash = {
		name: 'trash',
		label: '垃圾桶',
		icon: 'icon-trash',
		url: tempUrl + '?mode=trash',
		emptyInfo: {
			img: '/images/trash-empty.svg',
			title: '垃圾桶是空的',
			text: '所有被您刪除的' + tempLabel + '會出現在這裡，<br>您可以復原或者永久刪除它們。'
		}
	};
	itemNavs.folder = {
		name: 'folder',
		label: '分類',
		icon: 'zmdi-label-alt-outline',
		url: tempUrl + '?mode=folder',
		emptyInfo: {
			img: '/images/tag-empty.svg',
			title: '分類是空的',
			text: '將' + tempLabel + '拖曳到左測分類上，輕鬆分類' + tempLabel + '。'
		}
	};
	itemNavs.search = {
		name: 'search',
		label: '搜尋',
		icon: 'zmdi-search',
		url: 'javascript:void(0);',
		emptyInfo: {
			img: '/images/project-empty.svg',
			title: '沒有相關的' + tempLabel,
			text: '找不到相關的' + tempLabel + '，試試其他關鍵字？'
		}
	};
	itemNavs.redeem = {
		name: 'redeem',
		label: '已兌換' + tempLabel,
		icon: 'zmdi zmdi-check-all',
		url: tempUrl + '?mode=redeem',
		emptyInfo: {
			img: '/images/project-empty.svg',
			title: '沒有相關的' + tempLabel,
			text: '找不到相關的' + tempLabel + '，試試其他關鍵字？'
		}
	};

	function loadProjectsFn(mode, params, callback) {
		params = params || {};
		if (mode == 'invite') {
			MyProjectFactory.getInvitedProjects().then(function (items) {
				callback(items);
			});
		} else if (mode == 'trash') {
			MyProjectFactory.getTrash().then(function (items) {
				callback(items);
			});
		} else if (mode == 'folder') {
			FolderFactory.listProjects(params._id).then(function (items) {
				callback(items);
			});
		} else if (mode == 'submit') {
			UserFactory.listSealedProjects().then(function (resp) {
				var items = [];
				if (resp.status == 200){
					resp = resp.data;
					if (resp.success){
						items = resp.data;
					}
				}
				callback(items);
			});
		} else {
			if (mode == 'public') {
				params.isPublic = true;
			}
			MyProjectFactory.getProjects(params.isPublic).then(function (items) {
				callback(items);
			});
		}
	}

	function loadCoursesFn(mode, params, callback) {
		params = params || {};
		if (mode == 'redeem') {
			RedeemFactory.list().then(function (items) {
				callback(items);
			});
		} else if (mode == 'trash') {
			MyCourseFactory.getTrash().then(function (items) {
				callback(items);
			});
		} else if (mode == 'invite') {
			InviteCourseFactory.listInvites().then(function (items) {
				callback(items.data);
			});
		} else if (mode == 'submit') {
			UserFactory.listSealedCourses().then(function (resp) {
				var items = [];
				if (resp.status == 200){
					resp = resp.data;
					if (resp.success){
						items = resp.data;
					}
				}
				callback(items);
			});
		} else {
			if (mode == 'public') {
				params.isPublic = true;
			}
			MyCourseFactory.getCourses(params.isPublic).then(function (items) {
				callback(items);
			});
		}
	}

	return {
		setItemType: function (type, typeName, url) {
			itemType = type;
			itemLabel = typeName;
			basedUrl = url;
		},
		getItemType: function () {
			return itemType;
		},
		getNav: function (navName) {
			var itemNav = itemNavs[navName];
			itemNav.label = itemNav.label.replace(tempLabel, itemLabel);
			itemNav.emptyInfo.title = itemNav.emptyInfo.title.replace(tempLabel, itemLabel);
			itemNav.emptyInfo.text = itemNav.emptyInfo.text.replace(tempLabel, itemLabel);
			itemNav.url = itemNav.url.replace(tempUrl, basedUrl);
			return itemNav;
		},
		getLoadItemsFn: function (type) {
			if (type == 'project') {
				return loadProjectsFn;
			} else if (type == 'course') {
				return loadCoursesFn;
			} else {
				return function () {
					return [];
				};
			}
		},
		getInviteFactory: function (type) {
			if (type == 'project') {
				return InviteFactory;
			} else if (type == 'course') {
				return InviteCourseFactory;
			}
		},
		getNavs: function (type) {
			if (type == 'project') {
				return ['all', 'public', 'invite'];
			} else if (type == 'course') {
				return ['all', 'public', 'redeem', 'invite'];
			} else {
				return [];
			}
		},
		getRestoreFn: function () {
			if (itemType == 'project') {
				return MyProjectFactory.restore;
			} else if (itemType == 'course') {
				return MyCourseFactory.restore;
			} else {
				return function () {
					return null;
				};
			}
		}
	};
});
