/**
 * Created by Tinny Wang on 2017/11/21.
 */

app.controller('OrgHeaderController', function ($rootScope, $scope, $timeout, $ngModal, UserFactory) {
	
	var isMember = undefined;
	
	$scope.onSearchKeyup = function (event, keyword) {
		var keyCode = event.keyCode;
		if (keyCode === 13) {
			$timeout(function () {
				window.location = 'search?keyword=' + keyword;
			}, 10);
		}
	};
	
	$scope.signup = function () {
		$ngModal.openModal('signup-modal');
	};
	
	$scope.login = function () {
		$ngModal.openModal('login-modal');
	};
	
	$scope.logout = function () {
		UserFactory.logout();
	};
	
	$scope.join = function (isJoin) {
		
		if (isMember == undefined) {
			isMember = isJoin;
		}

		return isMember;
	};

	$rootScope.$on('ORG_MEMBER', function (e, params) {
		isMember = params.join;
	});

	$scope.openMobileSearch = function() {
		$scope.isSearchMode = true;
		$("#mobile-search").focus();
	};

	$scope.closeMobileSearch = function() {
		$scope.isSearchMode = false;
	};

	$scope.clearall = function() {
		$rootScope.searchKeyword = '';
		$("#search").focus();
	};
	
});
