/**
 * Created by Tinny Wang on 2017/12/7.
 */

app.controller('RemoveItemController', function ($scope, $ngModal, MyProjectFactory, MyCourseFactory) {

	$scope.isRemoving = false;

	$scope.$on("REMOVE_ITEM", function (e, params) {
		$scope.callback = params.callback;
		$scope.item = params.item;
		$ngModal.openModal("remove-item-modal");
		Mousetrap.bind('enter', function(e) {
			$scope.$apply(function() {
				$scope.remove();
			});
		});
	});

	function close () {
		$scope.item = undefined;
		$scope.isRemoving = false;
		$ngModal.closeModal("remove-item-modal");
		Mousetrap.unbind('enter');
	}

	$scope.remove = function (itemType) {
		$scope.isRemoving = true;
		var removeFn = undefined;
		if(itemType == 'project'){
			removeFn = MyProjectFactory.remove;
		} else if (itemType == 'course'){
			removeFn = MyCourseFactory.remove;
		} else {
			return;
		}
		removeFn({
			id: $scope.item._id
		}).then(function (data) {
			$scope.callback(data.data, $scope.item);
			close();
		});
	};
});
