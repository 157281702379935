/**
 * Created by Tinny Wang on 2017/11/17.
 */

app.controller('OrgModalController', function ($rootScope, $scope, $ngModal, OrgMemberFactory) {
	$scope.applicant = {};

	$scope.applyMember = function () {
		if(!$rootScope.userInfo){
			$ngModal.openModal('login-modal');
			return;
		}
		OrgMemberFactory.apply($rootScope.orgId,$scope.applicant).then(function(resp){
			var msg = '系統錯誤';
			if (resp.status == 200){
				resp = resp.data;
				if (resp.success){
					$ngModal.closeModal('member-apply-modal');
					msg = '完成申請';
					$rootScope.$broadcast("ORG_MEMBER", {
						join: 0 //0表示審請狀態是待審核
					});
				}else{
					msg = resp.msg;
				}
				
				$rootScope.promptMsg({
					msg: msg,
					icon: 'zmdi-info',
					iconColor: '#14A5FF'
				});
			}
		});
	};
	
});
