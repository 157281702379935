/**
 * Created by Tinny Wang on 2017/8/8.
 */

app.controller('SubscribeController', function ($scope, $http) {
	$scope.email = '';
	$scope.msg = '';
	
	$scope.subscribed = function () {
		if (!$scope.email){
			$scope.msg = 'E-mail格式錯誤';
			return;
		}
		$http({
			method: 'POST',
			url: '/api/subscribe/subscribed',
			data: {
				'email': $scope.email
			}
		}).
		then(function (resp) {
			console.log(resp);
			var data = resp.data,
				status = data.status;
			if(status === 'subscribed'){
				$scope.msg = '訂閱成功';
			} else if (status === 400){
				$scope.msg = '已訂閱過';
			} else {
				$scope.msg = data.title;
			}
			
		}, function (resp) {
			console.log(resp);
			$scope.msg = '訂閱失敗，請洽客服';
		});
	};
});
