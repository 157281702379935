app.controller("SignupController", function($rootScope, $scope, $http, $timeout, $location, $ngModal, $auth, UserFactory) {

    $scope.email = "";
    $scope.password = "";
    $scope.name = "";
    $scope.isSignuping = false;
    $scope.isPlicyShow = false;


    $scope.readPlicyGoogle = function() {
        $rootScope.$broadcast("SHOW_POLICY", {
            callback: function(isConfirm) {
                if (isConfirm) {
                    $scope.googlelogin();
                    // TDAPP.onEvent("Signup-Google");
                } else {

                    $timeout(function() {
                        $ngModal.closeModal("signup-modal");
                    }, 250);
                }
            }
        });
    };
    $scope.googlelogin = function() {
        if (UserFactory.user._id)
            return;
        $auth.authenticate('google').then(function(response) {
                var profile = response.data;
                location.reload();
            })
            .catch(function(response) {
                console.log(response);
            });
    };
    $scope.readPlicyFB = function() {
        $rootScope.$broadcast("SHOW_POLICY", {
            callback: function(isConfirm) {
                if (isConfirm) {
                    $scope.fblogin();
                    // TDAPP.onEvent("Signup-Facebook");
                } else {
                    $timeout(function() {
                        $ngModal.closeModal("signup-modal");
                    }, 250);
                }
            }
        });
    };

    $scope.fblogin = function() {
        if (UserFactory.user._id)
            return;
        $auth.authenticate('facebook').then(function(response) {
                var profile = response.data;
                location.reload();
            })
            .catch(function(response) {
                console.log(response);
            });
    };

    $scope.readPlicy = function() {
        $rootScope.$broadcast("SHOW_POLICY", {
            callback: function(isConfirm) {
                if (isConfirm) {
                    $scope.signup();
                } else {

                    $timeout(function() {
                        $ngModal.closeModal("signup-modal");
                    }, 250);
                }
            }
        });
    };


    $scope.signup = function() {

        if (!$scope.name || !$scope.email || !$scope.password) return;

        $scope.isSignuping = true;

        UserFactory.signup({
            name: $scope.name,
            email: $scope.email,
            password: $scope.password
        }).then(function(data) {
            var result = data.data;
            $scope.isSignuping = false;
            // TDAPP.onEvent("Signup-Email");
            if (!result.error) {
                $timeout(function() {
                    $ngModal.closeModal("signup-modal");
                }, 250);
                $ngModal.openModal("email-verify-modal");
            } else {
                $timeout(function() {
                    $ngModal.closeModal("signup-modal");
                }, 250);
                $ngModal.openModal("repeat-signup-modal");
            }
        });
    };

});
