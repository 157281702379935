app.controller("RemoveCourseController", function ($scope, $ngModal, MyProjectFactory, CategoryFactory,MyCourseFactory) {

	$scope.isRemoving = false;

	$scope.$on("REMOVE_COURSE", function (e, params) {
		$scope.callback = params.callback;
		$scope.course = params.course;
		$ngModal.openModal("remove-course-modal");
		Mousetrap.bind('enter', function(e) {
            $scope.$apply(function() {
            	$scope.remove();
            })
        });
	});

	function close () {
		$scope.course = undefined;
		$scope.isRemoving = false;
		$ngModal.closeModal("remove-course-modal");
		Mousetrap.unbind('enter');
	};

	$scope.remove = function () {
		$scope.isRemoving = true;

		MyCourseFactory.remove({
			id: $scope.course._id
		}).then(function (data) {
			$scope.callback(data.data, $scope.course);
			close();
		});
	};
});
