app.controller("RemoveChapterController", function ($scope, $ngModal, MyProjectFactory, CategoryFactory,MyCourseFactory) {

	$scope.isRemoving = false;

	$scope.$on("REMOVE_CHAPTER", function (e, params) {
		$scope.callback = params.callback;
		$scope.chapter = params.chapter;
		$ngModal.openModal("remove-chapter-modal");
		Mousetrap.bind('enter', function(e) {
            $scope.$apply(function() {
            	$scope.remove();
            })
        });
	});

	function close () {
		$scope.course = undefined;
		$scope.isRemoving = false;
		$ngModal.closeModal("remove-chapter-modal");
		Mousetrap.unbind('enter');
	};

	$scope.remove = function () {
		$scope.isRemoving = true;
		$scope.callback(true);
		close();
	};
});
