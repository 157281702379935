app.controller("LoginController", function($scope, $http, $timeout, $location, $auth, UserFactory) {

    // $scope.showFaceBookLogin = false;

    $scope.googlelogin = function() {
        if (UserFactory.user && UserFactory.user._id)
            return;
        $auth.authenticate('google', {
            deviceToken: window.localStorage.FIREBASE_TOKEN
        }).then(function(response) {
                var profile = response.data;
                location.reload();
            })
            .catch(function(response) {
                console.log(response);
            });
        // TDAPP.onEvent("Login-Google");
    };

    $scope.fblogin = function() {
        if (UserFactory.user && UserFactory.user._id)
            return;
        $auth.authenticate('facebook', {
            deviceToken: window.localStorage.FIREBASE_TOKEN
        }).then(function(response) {
                var profile = response.data;
                location.reload();
            })
            .catch(function(response) {
                console.log(response);
            });
        // TDAPP.onEvent("Login-Facebook");
    };

    $scope.onKeyup = function(event) {
        if (event.keyCode == 13) {
            $scope.emailLogin();
        }
    };

    $scope.emailLogin = function() {

        if (!$scope.email) {
            $scope.emptyEmail = true;
            return;
        }
        if (!$scope.password) {
            $scope.emptyPassword = true;
            return;
        }

        $scope.isLogining = true;
        $scope.notVerify = false;
        $scope.passwordError = false;
        $scope.notMemberError = false;
        $scope.emptyEmail = false;
        $scope.emptyPassword = false;
        UserFactory.login({
            email: $scope.email,
            password: $scope.password
        }).then(function(data) {
            var result = data.data;
            $scope.isLogining = false;
            if (result.error) {
                // 帳號密碼錯誤
                console.log(result);
                switch (result.error) {
                    case 1:
                        $scope.passwordError = true;
                        break;
                        // 信箱尚未驗證
                    case 2:
                        $scope.notMemberError = true;
                        break;
                    case 3:
                        $scope.notVerify = true;
                        break;
                }
            } else {
                $scope.notVerify = false;
                $scope.passwordError = false;
                $scope.notMemberError = false;
                // TDAPP.onEvent("Login_Email");
                location.reload();
            }

        });
    };


});
