app.controller("ProjectController", function($q, $scope, $rootScope, $http, $timeout, $location, UserFactory, ProjectFactory) {
    $scope.pageOver = false;
    $scope.projectId = $("#project").attr("project-id");
    $scope.project = {};
    $scope.isLiked = false;
    $scope.isLoading = true;
    $scope.cloneSuccess = false;
    $scope.isFollow = false;
    $scope.isFullScreenMode = false;
    $scope.checkIsOrignalProject = true;
    $scope.originAuthor = {};
    $scope.originProject = undefined;
    $scope.attachments = [];


	var attachmentItem = {
		_id: undefined,
		name: '',
		spKey: undefined
	};

    ProjectFactory.info($scope.projectId).then(function(data) {
        var result = data.data;

        if (!result.error) {
            $scope.project = result;
            console.log(result);
        }

        $scope.isLoading = false;

		setAttachments($scope.project.attachments);
        loadAuthor($scope.project.author._id);
        getOriginProjectInfo();


    });

    function getOriginProjectInfo() {
        var authorFlag = false;
        if ($scope.project.originAuthor && $scope.project.originAuthor != $scope.project.author._id) {
            $scope.checkIsOrignalProject = false;
            UserFactory.info($scope.project.originAuthor).then(function(data) {
                if (!data.data.error) {
                    $scope.originAuthor = data.data;
                    authorFlag = true;
                }
            });
        }

        if ($scope.project.originProjectId && $scope.project.originProjectId != $scope.project._id) {
            $scope.checkIsOrignalProject = false;
            ProjectFactory.info($scope.project.originProjectId).then(function(data) {
                if (!data.data.error) {
                    $scope.originProject = data.data;
                    if (!authorFlag) {
                        UserFactory.info(data.data.author._id).then(function(d) {
                            if (!d.data.error) {
                                $scope.originAuthor = d.data;
                                console.log($scope.originAuthor);
                            }
                        });
                    }
                }
            });
        } else {
            $scope.originProject = $scope.project;
        }

    }

    function loadAuthor(userId) {
        UserFactory.info(userId).then(function(data) {
            if (!data.data.error) {
                $scope.author = data.data;
            }
        });
    }

    function setAttachments(attachments) {
		$scope.attachments = attachments.map(function (attachment) {
			return angular.extend({}, attachmentItem, {
				_id: attachment.usageId,
				name: attachment.originalName,
				spKey: attachment.spKey
			});
		});
	}

    $scope.follow = function() {
        UserFactory.follow($scope.author._id).then(function(data) {
            var result = data.data;
            if (!result.error) {
                $scope.author.isFollow = true;
            }
        });
    };

    $scope.unfollow = function() {
        UserFactory.unfollow($scope.author._id).then(function(data) {
            var result = data.data;
            if (!result.error) {
                $scope.author.isFollow = false;
            }
        });
    };

    $scope.like = function() {
        ProjectFactory.like($scope.project._id).then(function(data) {
            var result = data.data;
            if (!result.error) {
                $scope.project.isLiked = true;
                $scope.project.likeCount++;
            }
        });
    };

    $scope.unlike = function() {
        ProjectFactory.unlike($scope.project._id).then(function(data) {
            var result = data.data;
            if (!result.error) {
                $scope.project.isLiked = false;
                $scope.project.likeCount--;
            }
        });
    };

    $scope.toogleFullScreen = function() {
        $scope.isFullScreenMode = !$scope.isFullScreenMode;
        $scope.$broadcast("resize");
    };

    // 複製專案
    $scope.cloneProject = function() {
        if (!UserFactory.user._id) return;
        $rootScope.$broadcast("CLONE_ITEM", {
            item: $scope.project,
            callback: function(result) {
                // 成功複製
                $scope.cloneSuccess = true;
                // TDAPP.onEvent("Project-Clone", $scope.project._id);
                $timeout(function() {
                    $scope.cloneSuccess = false;
                }, 4000);
            }
        });
    };

    // 列印專案
    // $scope.printProject = function() {
    //     if (!UserFactory.user._id) return;
    //     window.open('/project-print/'+$scope.projectId);
    // };

    $scope.getDownloadAttachmentUrl = function (spKey, fileName) {
		return ProjectFactory.getDownloadAttachmentUrl($scope.projectId, spKey, fileName);
	};
});
