app.controller("MoveToTrashController", function ($scope, $ngModal, MyProjectFactory, MyCourseFactory) {

	$scope.isRemoving = false;

	$scope.$on("MOVE_TO_TRASH", function (e, params) {
		$scope.callback = params.callback;
		$scope.itemIds = params.itemIds;
		$ngModal.openModal("move-trash-modal");
		Mousetrap.bind('enter', function(e) {
            $scope.$apply(function() {
            	$scope.remove();
            });
        });
	});

	function close () {
		$scope.itemIds = undefined;
		$scope.isRemoving = false;
		$ngModal.closeModal("move-trash-modal");
		Mousetrap.unbind('enter');
	}

	$scope.remove = function (itemType) {
		$scope.isRemoving = true;
		var moveToTrashFn = undefined,
			params = {};
		if(itemType == 'project'){
			moveToTrashFn = MyProjectFactory.moveToTrash;
			params.projectIds = $scope.itemIds.join();
		} else if (itemType == 'course'){
			moveToTrashFn = MyCourseFactory.moveToTrash;
			params.courseIds = $scope.itemIds.join();
		} else {
			return;
		}
		moveToTrashFn(params).then(function (data) {
			$scope.callback(data.data, $scope.project);
			close();
		});
	};
});
