app.controller("OrgCourseChapterController", function($q, $scope, $rootScope, $http, $timeout, $location, UserFactory, ProjectFactory, SealedCourseFactory, CategoryFactory) {

    $scope.courseId = $("#course").attr("course-id");
    $scope.project = {};
    $scope.isLiked = false;
    $scope.isLoading = true;
    $scope.isOpenSidebar = true;
    $scope.cloneSuccess = false;
    $scope.isFollow = false;
    $scope.isFullScreenMode = false;
    $scope.chapters = [];
    $scope.origin = location.origin;
    $scope.user = undefined;


    function loadAuthor(userId) {
        UserFactory.info(userId).then(function(data) {
            if (!data.data.error) {
                $scope.author = data.data;
            }
        });
    };

    // 載入課程基本資訊
    function loadCourse() {
        var deferred = $q.defer();
        SealedCourseFactory.info($scope.courseId).then(function(data) {
            $scope.course = data.data;
            deferred.resolve();
        });
        return deferred.promise;
    };

	$scope.UserFactory = UserFactory;

    $scope.init = function() {

        UserFactory.getUser().then(function(user) {
            if (user._id) {
                $scope.user = user;
                $scope.author = loadAuthor(user._id);
            } else {
                $scope.user = undefined;
            }
        });

		var unwatch = $scope.$watch('UserFactory.user', function(newValue, oldValue) {
			if(newValue){
				var UserID = newValue._id;
				// console.log('UserID: ' + UserID);
				dataLayer.push({'UserID': UserID});
			}
		});

        loadCourse();
    };
});
