app.controller("ShareCourseController", function($scope, $ngModal, $timeout, MyCourseFactory, CategoryFactory, ProjectFactory, FolderFactory, MyProjectFactory, TicketFactory) {


    //初始化

    $scope.isUpdating = false;
    $scope.callback;
    $scope.selected = {};
    $scope.courses = [];
    $scope.step = 1;
    $scope.isNextStepReject = false;
    $scope.categories = [];


    $scope.$on("SHARE_COURSE", function(e, params) {


        $scope.callback = params.callback;
        $scope.folderId = "";
        $scope.inviteMode = false;
        $scope.selected = {};
        $scope.courses = [];
        $scope.step = 1;
        $scope.isNextStepReject = false;
        $scope.categories = [];
        $scope.name = "";
        $scope.isPublic = false;
        $scope.description = "";
        $scope.thumbnail = "";
        $scope.thumbnailName = "";
        $scope.selected.tag = [];



        // 載入 Categories
        CategoryFactory.getCategories().then(function(categories) {

            $scope.categories = angular.copy(categories);

            //讀取專案
            loadCourses();
            $ngModal.openModal("share-course-modal");
        });
    });


    // 載入課程
    function loadCourses() {
        $scope.isLoading = true;
        MyCourseFactory.getCourses($scope.isPublic).then(function(courses) {
            $scope.courses = courses;
            $scope.isLoading = false;
        });

    }

    function close() {
        $scope.name = "";
        $scope.description = "";
        $scope.thumbnail;
        $scope.categories = [];
        $scope.isCreating = false;
        $ngModal.closeModal("share-course-modal");
        $scope.callback();
    };


    $scope.close = function() {
        close();
    }

    $scope.selectCourse = function(event, course) {
        clearSelected();

        event.preventDefault();
        event.stopPropagation();
        $scope.selected = course;
        var idx = $scope.courses.indexOf(course);
        course.isSelected = true;

    };



    function clearSelected() {
        $scope.courses.forEach(function(course) {
            course.isSelected = false;
        });
        $scope.selected = {};
    };




    $scope.prevStep = function() {
        $scope.selected = {};
        $scope.step = 1;
        $scope.isNextStepReject = false;
        $scope.thumbnail = "";
        $scope.thumbnailName = "";
        $scope.selected.tag = [];
    }



    $scope.nextStep = function() {
        // console.log($scope.selected);
        if ($scope.step == 1) {
            //檢查第一個完成沒
            if ($scope.selected._id) {
                // selectThisCourse($scope.selected);
                labelTags();
                $scope.isNextStepReject = false;
                $scope.step++;

            } else {
                $scope.isNextStepReject = true;
                return;
            }

        } else if ($scope.step == 2) {
            // 檢查是否填寫

            // selectThisCourse($scope.selected);

            update(function() {
                applyPublicCourse($scope.selected._id, function() {
                    $scope.step++;
                });
            });


        }



    }


    function applyPublicCourse(id, callback) {
        TicketFactory.applyPublicCourse({
            courseId: id
        }).then(function(data) {
            callback();
        });
    }


    function labelTags() {
        // console.log($scope.selected);


        $scope.thumbnail = $scope.selected.thumbnail;
        // $scope.thumbnailName = course.thumbnail && course.thumbnail.split(/[/]+/).pop();
        $scope.selected.tag = [];
        if ($scope.selected.tags == ",")
            $scope.selected.tags = "";
        if ($scope.selected.tags != "")
            $scope.selected.tag = $scope.selected.tags.split(',');


        $scope.categories.forEach(function(category, j) {
            $scope.categories[j].checked = false;

            if ($scope.selected.categories && $scope.selected.categories.length > 0) {
                $scope.selected.categories.forEach(function(isCheckedCourse, i) {
                    if (isCheckedCourse._id == category._id) {
                        $scope.categories[j].checked = true;
                    }
                });
            }
        });
    }




    // function selectThisCourse(course) {
    //     console.log(course);
    //     $scope.courseId = course._id;
    //     $scope.name = course.name;
    //     $scope.isPublic = course.isPublic;
    //     $scope.description = course.description;
    //     $scope.thumbnail = course.thumbnail;
    //     $scope.thumbnailName = course.thumbnail && course.thumbnail.split(/[/]+/).pop();
    //     if (course.tags)
    //         $scope.selected.tag = course.tags.split(',');
    //     $scope.categories.forEach(function(category, j) {
    //         $scope.categories[j].checked = false;
    //         if (course.categories && course.categories.length > 0) {
    //             course.categories.forEach(function(isCheckedCourse, i) {
    //                 if (isCheckedCourse._id == category._id) {
    //                     $scope.categories[j].checked = true;
    //                 }
    //             });
    //         }
    //     });
    // }


    function getCheckedCategoryIds() {
        var checkeds = $scope.categories.filter(function(category) {
            if (category.checked) {
                return true;
            }
            return false;
        });
        var categoryIds = checkeds.map(function(c) {
            return c._id;
        });
        return categoryIds;
    };
    $scope.uploadThumbnail = function($file) {
        $scope.uploadingThumbnail = true;
        var upload = MyCourseFactory.uploadThumbnail($file);
        upload.then(function(resp) {
            $scope.backgroundColor = resp.data.backgroundColor;
            $scope.thumbnail = resp.data.Location;
            $scope.selected.thumbnail = resp.data.Location;
            $scope.uploadingThumbnail = false;
        }, function(resp) {
            $scope.uploadingThumbnail = false;
        }, function(evt) {
            $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
            $scope.fileName = evt.config.data.file.name;
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
        });
    };



    function update(callback) {
        $scope.isUpdating = true;
        var categoryIds = getCheckedCategoryIds().join();

        var tags = [];
        if ($scope.selected.tag.length > 0)
            tags = $scope.selected.tag.map(function(tag) {
                return tag.text;
            });



        var newCourse = {
                id: $scope.selected._id,
                name: $scope.selected.name,
                description: $scope.selected.description,
                categories: categoryIds,
                tags: tags.join(),
                thumbnail: $scope.selected.thumbnail,
                isPublic: $scope.selected.isPublic,
                backgroundColor: $scope.selected.backgroundColor
            }
            //防呆
        if ($scope.selected.ticketCoursePublicId)
            newCourse.ticketCoursePublicId = $scope.selected.ticketCoursePublicId;

        MyCourseFactory.update(newCourse).then(function(data) {
            callback();
            $scope.isUpdating = false;

        });
    };




});
