app.controller("UpdateProjectController", function ($scope, $ngModal, MyProjectFactory, CategoryFactory, ProjectFactory) {


	//初始化
	var attachmentItem = {
		_id: undefined,
		name: '',
		url: '',
		progress: 0,
		isUploading: false,
		err: undefined,
		spKey: undefined
	};
	$scope.attachmentTypes = [
		'application/pdf', // pdf
		'application/vnd.ms-powerpoint', // ppt
		'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
		'application/msword', // doc
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
		'application/vnd.ms-excel', // xls
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' //xlsx
	];
	clear();

	// 2/25 James edited
    $scope.isPublicOptions = [
    { name: "公開", value: "true" },
    { name: "不公開", value: "false" }
    ];

    // 2/25 James edited
    $scope.allowCloneOptions = [
    { name: "是", value: "true" },
    { name: "否", value: "false" }
    ];

	function clear() {
		$scope.projectId = undefined;
		$scope.name = undefined;
		$scope.description = undefined;
		$scope.thumbnail = undefined;
		$scope.isUpdating = false;
		$scope.isCreating = false;
		$scope.categories = [];
		$scope.projectData = {
			isPublic: false,
			allowClone: true
		};
		$scope.attachments = [];
		$ngModal.closeModal("update-project-modal");
	}

	function initialProject(project) {
		$scope.name = project.name;

		$scope.projectData = {
	        isPublic: initialIsPublic(project.isPublic),
	        allowClone: initialIsAllowClone(project.allowClone)
        };

		$scope.description = project.description;
		$scope.thumbnail = project.thumbnail;
		$scope.thumbnailName = project.thumbnail && project.thumbnail.split(/[/]+/).pop();
		$scope.categories.forEach(function (category, j) {

			$scope.categories[j].checked = false;
			if (project.categories && project.categories.length > 0) {
				project.categories.forEach(function (isCheckedProject, i) {
					if (isCheckedProject._id == category._id) {
						$scope.categories[j].checked = true;
					}
				});
			}
		});

		project.attachments = project.attachments || [];

		$scope.attachments = project.attachments.map(function (attachment) {
			return angular.extend({}, attachmentItem, {
				_id: attachment.usageId,
				name: attachment.originalName,
				spKey: attachment.spKey
			});
		});
	}
	function initialIsPublic(isPublic){
		var condition = isPublic ? isPublic.toString() : "false";

		for (i = 0; i < $scope.isPublicOptions.length; i++) {
			if ($scope.isPublicOptions[i].value === condition) {
				return $scope.isPublicOptions[i].value;
			}
		}
		return $scope.isPublicOptions[1].value;
	}
	function initialIsAllowClone(allowClone){
		var condition = allowClone ? allowClone.toString() : "false";

		for (i = 0; i < $scope.allowCloneOptions.length; i++) {
			if ($scope.allowCloneOptions[i].value === condition) {
				return $scope.allowCloneOptions[i].value;
			}
		}
		return $scope.allowCloneOptions[1].value;;
	}
	function getCheckedCategoryIds() {
		var checkeds = $scope.categories.filter(function (category) {
			if (category.checked) {
				return true;
			}
			return false;
		});
		var categoryIds = checkeds.map(function (c) {
			return c._id;
		});
		return categoryIds;
	}

	$scope.$on("UPDATE_PROJECT", function (e, project, callback) {
		clear();

		$scope.callback = callback;
		$scope.project = project;
		$scope.projectId = project._id;
		$scope.tags = [];

        let tags = project.tags;
        if (tags) {
            $scope.tags = tags.map(function(tag) {
                return {
                    text: tag
                };
            });
        }

		// 載入 Categories
		CategoryFactory.getCategories().then(function (categories) {

			$scope.categories = angular.copy(categories);

			//讀取專案
			initialProject(project);
			$ngModal.openModal("update-project-modal");
		});
	});

	$scope.uploadThumbnail = function ($file) {
		$scope.uploadingThumbnail = true;
		var upload = MyProjectFactory.uploadThumbnail($file);
		upload.then(function (resp) {
			$scope.thumbnail = resp.data.Location;
			$scope.uploadingThumbnail = false;
		}, function (resp) {
			$scope.uploadingThumbnail = false;
		}, function (evt) {
			$scope.progress = parseInt(100.0 * evt.loaded / evt.total);
			$scope.fileName = evt.config.data.file.name;
			console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
		});
	};

	$scope.uploadAttachment = function ($file) {
		if (!$file) {
			return;
		}

		var attachment = angular.extend({}, attachmentItem);
		var upload = MyProjectFactory.uploadAttachment($scope.projectId, $file);

		attachment.isUploading = true;
		$scope.attachments.push(attachment);
		upload.then(function (resp) {
			console.log('>>> Success!!!');
			console.log(resp);
			resp = resp.data;
			if (resp.success) {
				var data = resp.data,
					link = data.link,
					info = data.info;
				attachment._id = link._id;
				attachment.url = info.url;
				attachment.isUploading = false;
			} else {
				attachment.err = resp.msg;
			}
		}, function (resp) {
			attachment.err = resp;
			attachment.isUploading = false;
		}, function (evt) {
			attachment.progress = parseInt(100.0 * evt.loaded / evt.total);
			attachment.name = evt.config.data.file.name;
			// console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
		});
	};

	$scope.removeAttachment = function (attachmentId) {
		_.remove($scope.attachments, {
			_id: attachmentId
		});
	};

	$scope.update = function () {
		$scope.isUpdating = true;
		var categoryIds = getCheckedCategoryIds();
		var tags = $scope.tags.map(function (tag) {
				return tag.text;
			}),
			attachments = $scope.attachments.map(function (attachment) {
				return attachment._id;
			});
		MyProjectFactory.update({
			id: $scope.projectId,
			name: $scope.name,
			description: $scope.description,
			categories: categoryIds.join(),
			tags: tags,
			thumbnail: $scope.thumbnail,
			isPublic: $scope.projectData.isPublic,
			allowClone: $scope.projectData.allowClone,
			attachments: attachments
		}).then(function (resp) {
			$scope.isUpdating = false;
			if (resp.data.error) {
				// TODO 錯誤處理
				$scope.isError = true;
			} else {
				$scope.callback(resp.data);
				clear();
			}
		});
	};

	$scope.getDownloadAttachmentUrl = function (spKey, fileName) {
		return ProjectFactory.getDownloadAttachmentUrl($scope.projectId, spKey, fileName);
	};
});
