app.controller("CourseController", function($q, $scope, $rootScope, $http, $timeout, $location, UserFactory, ProjectFactory, CourseFactory, RedeemFactory, CategoryFactory) {

    $scope.courseId = $("#course").attr("course-id");
    $scope.projectId = $("#course").attr("project-id");
    $scope.project = {};
    $scope.isLiked = false;
    $scope.isLoading = true;
    $scope.isOpenSidebar = true;
    $scope.cloneSuccess = false;
    $scope.isFollow = false;
    $scope.isFullScreenMode = false;
    $scope.chapters = [];
    $scope.origin = location.origin;
    $scope.user = undefined;

    $scope.isexpireTime = false;

    $scope.isExpired = false;
    $scope.isRedeemEvent = false;
    $scope.isShowExpiredHint = false;
    //reddem相關
    //課程屏蔽
    $scope.redeemHide = true;
    $scope.isRedeemed = false;

	$scope.attachments = [];

	var attachmentItem = {
		_id: undefined,
		name: '',
		spKey: undefined
	};

    // ProjectFactory.info($scope.projectId).then(function(data) {
    //     var result = data.data;
    //     if (!result.error) {
    //         $scope.project = result;
    //     }
    //     $scope.isLoading = false;
    //     loadAuthor($scope.project.author._id);
    //     $scope.loadCourseChapters();
    // });

    function loadAuthor(userId) {
        UserFactory.info(userId).then(function(data) {
            if (!data.data.error) {
                $scope.author = data.data;
            }
        });
    }

    function loadRedeem(courseId) {
        RedeemFactory.checkRedeem(courseId).then(function(data) {
            if (!data.error) {
                $scope.isRedeemed = true;
                if ($scope.isRedeemed) {
                    $scope.redeemHide = false;
                }
            } else {
                $scope.isRedeemed = false;
            }
        });
    }

    function createPDF(name) {
        console.log("msg");
        // var source = $('#pdf-area')[0];
        var source = $('body')[0];
        html2canvas(source, {
            onrendered: function(canvas) {
                console.log(canvas.height);
                console.log(canvas.width);

                var data = canvas.toDataURL();
                var docDefinition = {
                    content: [{
                        image: data,
                        width: 595
                    }],
                    pageSize: 'A4',
                    pageMargins: [0, 0, 0, 0]
                        // ,
                        // defaultStyle: {
                        //     font: 'msjh'
                        // }
                };
                pdfMake.fonts = {
                    Roboto: {
                        normal: 'Roboto-Regular.ttf',
                        bold: 'Roboto-Medium.ttf',
                        italics: 'Roboto-Italic.ttf',
                        bolditalics: 'Roboto-Italic.ttf'
                    },
                    msjh: {
                        normal: 'msjh.ttf',
                        bold: 'msjh.ttf',
                        italics: 'msjh.ttf',
                        bolditalics: 'msjh.ttf',
                    }
                };
                pdfMake.createPdf(docDefinition).download(name + ".pdf");
            }
        });
    }

	function checkRedeemState(courseId) {
		RedeemFactory.checkRedeemState($scope.courseId).then(function(data) {

			if (!data.error && data.expireAt) {
				// console.log(data)
				//有兌換活動
				$scope.isRedeemEvent = true;
				$scope.isexpireTime = true;
				$scope.expireAt = data.expireAt;

				var date = new Date();
				var now = date.getTime();
				var expireAt = new Date(data.expireAt);
				var event = expireAt.getTime();

				if (event - now > 0) {
					$scope.isExpired = false;
				} else {
					$scope.isExpired = true;
				}


				$scope.redeemHide = true;
			} else {
				$scope.isRedeemEvent = false;
				//課程不用屏蔽
				if( $scope.user){
					$scope.redeemHide = false;
				}
				else{
					$scope.redeemHide = true;
				}


			}
		});
	}

	function RedeemInit(courseId) {
		console.log('courseId: ' + courseId);
		CourseFactory.info(courseId).then(function (resp) {
			console.log(resp.data.isShared);
			if(resp.data.isShared != true){
				//時間獲取
				checkRedeemState(courseId);
				//是否兌換獲取
				loadRedeem(courseId);
				// 如果是開放 //false
				$scope.redeemHide = true;
			}

		}, function (error) {

		});

		// //時間獲取
		// checkRedeemState(courseId);
		// //是否兌換獲取
		// loadRedeem(courseId);
		// // 如果是開放 //false
		// $scope.redeemHide = true;


	}

	function creatTags() {
		// console.log($scope.course);
		$scope.tagsToString = "";
		// console.log($scope.course.categories);
		if ($scope.course.categories && $scope.course.categories.length > 0) {
			$scope.course.categories.forEach(function(c, i) {
				$scope.categories.forEach(function(l, j) {
					if (l._id == c) {
						// console.log(l.name);
						$scope.tagsToString = l.name.toString() + " ";
						// console.log($scope.tagsToString);
					}

				});


			});
		}

	}

	// 載入課程基本資訊
	function loadCourse() {
		var deferred = $q.defer();
		CourseFactory.info($scope.courseId).then(function(data) {
			$scope.course = data.data;
			setAttachments($scope.course.attachments);
			deferred.resolve();
		});
		return deferred.promise;
	}

	//將project資料分別載入
	function loadProjectInfo() {
		$scope.chapters.forEach(function(chapter, i) {
			if (chapter.projects) {
				chapter.projects.forEach(function(project, j) {
					ProjectFactory.info(project._id).then(function(data) {
						$scope.chapters[i].projects[j] = data.data;
					});
				});
			} else {
				$scope.chapters[i].projects = [];
			}
		});
	}

	function setAttachments(attachments) {
		$scope.attachments = attachments.map(function (attachment) {
			return angular.extend({}, attachmentItem, {
				_id: attachment.usageId,
				name: attachment.originalName,
				spKey: attachment.spKey
			});
		});
	}

    //     function createQRcode() {
    //         // var source = $('#QRcode')[0];
    //         // var qrcode = new QRCode(source, {
    //         //     text: "http://jindo.dev.naver.com/collie",
    //         //     width: 128,
    //         //     height: 128,
    //         //     colorDark: "#000000",
    //         //     colorLight: "#ffffff",
    //         //     correctLevel: QRCode.CorrectLevel.H
    //         // });
    // new QRCode(document.getElementById("QRcode"), "http://jindo.dev.naver.com/collie");
    //     }

	$scope.UserFactory = UserFactory;

	$scope.exchangeCourse = function() {
		if (UserFactory.user._id) {
			$rootScope.$broadcast("EXCHANGE_COURSE", {
				courseId: $scope.courseId,
				expireAt: $scope.expireAt,
				callback: function(result) {
					if (result) {
						//兌換成功
						$scope.redeemHide = false;
						$scope.isRedeemed = true;
					}
				}
			});
		}
	};

	$scope.showRedeemHint = function() {

		if (!$scope.isExpired) {
			$scope.isShowRedeemHint = true;
			$timeout(function() {
				$scope.exchangeCourse();
				$scope.isShowRedeemHint = false;
			}, 2000);
		} else {
			$scope.isShowExpiredHint = true;
			$timeout(function() {
				$scope.isShowExpiredHint = false;
			}, 2000);

		}
	};

	$scope.creatPDFfromHTML = function(name) {
		// 去空白
		createPDF(name.replace(/\s+/g, ""));
	};

    // 載入課程章節列表
    $scope.loadCourseChapters = function() {
        $scope.allProjectLength = 0;
        $scope.courseId = $("#course").attr("course-id");
        CourseFactory.getChapters($scope.courseId).then(function(data) {
            if (data.data.length > 0) {
                data.data.forEach(function(chapter, i) {
                    $scope.chapters.push(chapter);
                });
            }
            $scope.chapters.forEach(function(chapter) {
                // 找到當前專案在課程的位置
                // 讓該章節預設展開 chapter.isExpand = true
				if (!chapter.projects){
					return;
				}
                chapter.projects.forEach(function(project) {
                    $scope.allProjectLength++;
                    chapter.isExpand = true;
                    if ($scope.projectId === project._id) {
                        $scope.currentProject = project;
                        chapter.isCurrentChapter = true;
                        return;
                    }
                });
            });
        });
    };

    $scope.init = function() {

        UserFactory.getUser().then(function(user) {
            if (user._id) {
                $scope.user = user;

            } else {
                $scope.user = undefined;
            }
        });

		var unwatch = $scope.$watch('UserFactory.user', function(newValue, oldValue) {
			if(newValue){
				var UserID = newValue._id;
				// console.log('UserID: ' + UserID);
				dataLayer.push({'UserID': UserID});
			}
		});

        RedeemInit($scope.courseId);

        var task1 = loadCourse();
        var task2 = $scope.loadCourseChapters();
        $q.all([task1, task2]).then(function() {
            loadProjectInfo();

            CategoryFactory.getCategories().then(function(categories) {
                $scope.categories = angular.copy(categories);
                creatTags();
            });


            // console.log($scope.chapters);

        });

    };

    $scope.like = function () {
        CourseFactory.like($scope.courseId).then(function (data) {
            var result = data.data;
            if (!result.error) {
                $scope.course.isLiked = true;
                $scope.course.likeCount++;
            }
        });
    };

    $scope.unlike = function () {
        CourseFactory.unlike($scope.courseId).then(function (data) {
            var result = data.data;
            if (!result.error) {
                $scope.course.isLiked = false;
                $scope.course.likeCount--;
            }
        });
    };

	$scope.cloneCourse = function() {
		if (!UserFactory.user._id) return;
		$rootScope.$broadcast("CLONE_ITEM", {
			item: $scope.course,
			callback: function(result) {
				// 成功複製
				$scope.cloneSuccess = true;
				// TDAPP.onEvent("Course-Clone", $scope.course._id);
				$timeout(function() {
					$scope.cloneSuccess = false;
				}, 4000);
			}
		});
	};

	// 列印課程
    $scope.printCourse = function() {
    	if (!UserFactory.user._id) return;
    	window.open('/course-print/'+$scope.courseId);
    };

	$scope.getDownloadAttachmentUrl = function (spKey, fileName) {
		return CourseFactory.getDownloadAttachmentUrl($scope.courseId, spKey, fileName);
	};

});
