/**
 * Created by Tinny Wang on 2017/9/5.
 */

app.controller('CloneCourseController', function($scope, $ngModal, MyCourseFactory){
	$scope.isCloning = false;

	$scope.$on("CLONE_COURSE", function (e, params) {
		$scope.callback = params.callback;
		$scope.course = params.course;
		$scope.newName = $scope.course.name;
		$scope.newDescription = $scope.course.description;
		$scope.folderId = params.folderId;
		$ngModal.openModal("clone-course-modal");
	});

	function close () {
		$scope.course = undefined;
		$scope.newName = undefined;
		$scope.newDescription = undefined;
		$scope.isCloning = false;
		$ngModal.closeModal("clone-course-modal");
	}

	$scope.clone = function () {
		$scope.isCloning = true;
		MyCourseFactory.clone($scope.course, $scope.newName, $scope.newDescription, $scope.folderId).then(function (data) {
			if (data.data) {
				$scope.callback(data.data);
				close();
			}
		});
	};
});
