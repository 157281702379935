/**
 * Created by Tinny Wang on 2017/12/19.
 */
app.controller('ReadmooController', function ($scope, AdFactory) {
	
	$scope.items = [];
	
	AdFactory.readmoo({
		count: 1,
		offset: Math.ceil(Math.random()*10)
	}).then(function (resp) {
		if (resp.status == 200){
			resp = resp.data;
			$scope.items = resp.data || [];
			console.log($scope.items);
		}
	});
	
	$scope.textModifier = function (text) {
		let textLen = text.length,
			limit = 80;
		text = text.replace(/\n/g, '').replace(/\r/g, '');
		return textLen > limit ? text.substring(0, limit) + '...' : text;
	};
	
});
