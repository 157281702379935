app.controller("ExchangeCourseController", function($scope, RedeemFactory, $timeout, $ngModal, InviteFactory, UserFactory, MyCourseFactory, InviteCourseFactory, TicketFactory) {


    $scope.isSending = false;
    $scope.codeMode = true;
    $scope.redeemSuccessed = false;
    $scope.redeem = {};

    $scope.$on("EXCHANGE_COURSE", function(e, params) {
        $scope.callback = params.callback;
        $scope.courseId = params.courseId;
        $scope.expireAt = params.expireAt;
        console.log($scope.courseId);

        $ngModal.openModal("exchange-course-modal");


    });





    $scope.apply = function() {
        console.log("applying");
        console.log($scope.redeem.code);
        if ($scope.redeem.code)
            $scope.isSending = true;
            RedeemFactory.create($scope.courseId, $scope.redeem.code).then(function(data) {
                console.log(data.data);
                if (!data.data.error) {
                    console.log(data.data);
                    $scope.isSending = false;
                    $scope.callback(true);
                    close();

                } else {
                    //代碼錯誤
                    $scope.isSending = false;
                    $scope.codeMode = false;
                    $scope.redeemSuccessed = false;
                }

            });

    };


    $scope.redeemAgain = function() {
        $scope.isSending = false;
        $scope.codeMode = true;
        $scope.redeemSuccessed = false;
    }



    function close() {
        $scope.name = "";
        $scope.isPublic = false;
        $scope.description = "";
        $scope.thumbnail;
        $scope.isSending = false;
        $ngModal.closeModal("exchange-course-modal");
    };


});
