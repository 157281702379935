app.controller("CourseCancelPublicController", function($scope, $ngModal, MyCourseFactory, CategoryFactory) {

    $scope.isChanging = false;

    $scope.$on("CANCEL_PUBLIC_COURSE", function(e, params) {
        $scope.callback = params.callback;
        $scope.course = params.course;
        $ngModal.openModal("course-cancel-public-modal");
        Mousetrap.bind('enter', function(e) {
            $scope.$apply(function() {
                $scope.unPublic();
            })
        });
    });

    function close() {
        $scope.projectIds = undefined;
        $scope.isChanging = false;
        $ngModal.closeModal("course-cancel-public-modal");
        Mousetrap.unbind('enter');
    };

    $scope.unPublic = function() {
        $scope.isChanging = true;

        MyCourseFactory.update({
            id: $scope.course._id,
            name: $scope.course.name,
            description: $scope.course.description,
            isPublic: false
        }).then(function(data) {
            $scope.callback(data);
            close();
        });


    };
});
