app.controller("EmptyTrashController", function ($scope, $ngModal, MyProjectFactory, MyCourseFactory) {

	$scope.isRemoving = false;

	$scope.$on("EMPTY_TRASH", function (e, params) {
		$scope.callback = params.callback;
		$ngModal.openModal("empty-trash-modal");
	});

	function close () {
		$scope.isRemoving = false;
		$ngModal.closeModal("empty-trash-modal");
	}

	$scope.remove = function (itemType) {
		$scope.isRemoving = true;
		var emptyTrashFn = undefined;
		if(itemType == 'project'){
			emptyTrashFn = MyProjectFactory.emptyTrash;
		} else if (itemType == 'course'){
			emptyTrashFn = MyCourseFactory.emptyTrash;
		} else {
			return;
		}
		emptyTrashFn().then(function (data) {
			$scope.callback(data.data);
			close();
		});
	};
});
