/**
 * Created by Tinny Wang on 2017/11/24.
 */

app.controller('CloneItemController', function ($scope, $ngModal, MyProjectFactory, MyCourseFactory, SealedProjectFactory, SealedCourseFactory) {

	var modalName = 'clone-item-modal',
		MyItem = null;

	$scope.isCloning = false;

	$scope.$on("CLONE_ITEM", function (e, params) {
		$scope.callback = params.callback;
		$scope.item = params.item;
		$scope.newName = $scope.item.name;
		$scope.newDescription = $scope.item.description;
		$scope.folderId = params.folderId;
		$ngModal.openModal(modalName);
	});

	$scope.setType = function (itemType) {
		if (itemType == 'project') {
			MyItem = MyProjectFactory;
		} else if (itemType == 'course') {
			MyItem = MyCourseFactory;
		} else if (itemType == 'sealed-project') {
			MyItem = SealedProjectFactory;
		} else if (itemType == 'sealed-course') {
			MyItem = SealedCourseFactory;
		}
	};

	$scope.clone = function () {
		$scope.isCloning = true;
		MyItem.clone($scope.item, $scope.newName, $scope.newDescription, $scope.folderId)
			.then(function (data) {
				if (data.data) {
					$scope.callback(data.data);
					close();
				}
			});
	};

	function close() {
		$scope.item = undefined;
		$scope.newName = undefined;
		$scope.newDescription = undefined;
		$scope.isCloning = false;
		$ngModal.closeModal(modalName);
	}

});
