/**
 * Created by Tinny Wang on 2017/9/15.
 */

app.controller('NavController', function ($rootScope, $scope, $routeParams, $location, NavFactory) {
	
	var navType = $routeParams.type || $rootScope.navType,
		subType = $routeParams.subType || $rootScope.subType;
	
	$rootScope.baseType = $location.$$path.split('/')[1];
	$rootScope.navType = navType;
	$rootScope.subType = subType;

	$scope.$parent.tableInfos = [];
	$scope.$parent.chartInfos = [];

	NavFactory.setScope($scope);
	// $scope.cols = NavFactory[$rootScope.baseType][navType][subType] || [];
	$scope.cols = NavFactory[$rootScope.baseType][navType];
	$scope.cols = $scope.cols && $scope.cols[subType] || $scope.cols || [];

	for (var i = 0; i < $scope.cols.length; i++){
		var colsInfo = $scope.cols[i];
		$scope.$parent.tableInfos.push({
			name: colsInfo.name,
			cols: colsInfo.cols
		});
	}

	$rootScope.$broadcast("NAV_SUCCESS", {
		navType: navType,
		subType: subType,
		scope: $scope
	});
});
