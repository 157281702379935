/**
 * Created by Tinny Wang on 2017/11/21.
 */

app.controller('OrgMemberController', function ($rootScope, $scope, OrgFactory) {

	$scope.isFollowed = false;

	$scope.followToggle = function () {
		var followFn, msg = '系統錯誤';

		followFn = $scope.isFollowed ? OrgFactory.unfollow : OrgFactory.follow;

		followFn($rootScope.orgId).then(function (resp) {
			console.log(resp);
			if(resp.status == 200){
				resp = resp.data;
				if(resp.success){
					$scope.isFollowed = !$scope.isFollowed;
				}else{
					msg = resp.msg;
				}
			}
		});
	};
	
});
