/**
 * Created by Tinny Wang on 2017/11/3.
 */

app.controller('ShareItemController', function ($scope, $ngModal, ShareItemFactory, CategoryFactory, UserFactory) {

	var modalName = 'share-item-modal',
		ItemShare = null;
	
	var currStep = 0;
	
	$scope.targetOrgs = [];
	
	$scope.stepInfos = [{
		name: 'select',
		label: '選擇投稿',
		valid: function (callback) {
			var pass = false,
				msg = false;
			if ($scope.selected._id) {
				labelTags();
				pass = true;
			} else {
				msg = '請選擇';
			}
			callback(pass, msg);
		}
	}, {
		name: 'confirm',
		label: '分享資訊確認',
		valid: function (callback) {
			update(function(resp) {
				var shareTargetId = $scope.shareTarget._id,
					sharePublic = false;
				
				if (shareTargetId == 'jibao'){
					sharePublic = true;
				}

				ItemShare.shareApply(sharePublic, {
					orgId: shareTargetId,
					itemId: $scope.selected._id
				}).then(function(resp) {
					callback(true, false);
				});
			});
		}
	}, {
		name: 'submitted',
		label: '投稿成功'
	}];
	
	$scope.$on("SHARE_ITEM", function(e, params) {
		_init(params.shareType, params);
		_getUserOrgs();
		CategoryFactory.getCategories().then(function(categories) {
			$scope.categories = angular.copy(categories);
			_loadItems();
			$ngModal.openModal(modalName);
		});
	});

	$scope.isStep = function (stepName) {
		return stepName == $scope.stepInfos[currStep].name;
	};
	
	$scope.hasPrevStep = function () {
		return currStep > 0;
	};
	
	$scope.prevStep = function() {
		currStep--;
		$scope.selected = {};
		$scope.thumbnail = "";
		$scope.thumbnailName = "";
	};

	$scope.nextStep = function() {
		var stepInfo = $scope.stepInfos[currStep];
		stepInfo.valid(function (pass, msg) {
			if (pass) {
				currStep++;
			}
			$scope.invalidMsg = msg;
		});
	};

	$scope.close = function() {
		close();
	};

	$scope.selectItem = function(event, course) {
		clearSelected();
		event.preventDefault();
		event.stopPropagation();
		$scope.selected = course;
		var idx = $scope.items.indexOf(course);
		course.isSelected = true;
	};

	$scope.uploadThumbnail = function($file) {
		$scope.uploadingThumbnail = true;
		var upload = ItemShare.uploadThumbnail($file);
		upload.then(function(resp) {
			$scope.backgroundColor = resp.data.backgroundColor;
			$scope.thumbnail = resp.data.Location;
			$scope.selected.thumbnail = resp.data.Location;
			$scope.uploadingThumbnail = false;
			
		}, function(resp) {
			$scope.uploadingThumbnail = false;
		}, function(evt) {
			$scope.progress = parseInt(100.0 * evt.loaded / evt.total);
			$scope.fileName = evt.config.data.file.name;
			console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :' + evt.config.data.file.name);
		});
	};
	
	function _loadItems() {
		$scope.isLoading = true;
		ItemShare.getItems($scope.isPublic).then(function(items) {
			$scope.items = items;
			$scope.isLoading = false;
		});
	}

	function _setType(itemType) {
		if (itemType == 'course'){
			ItemShare = ShareItemFactory.Course;
		} else if(itemType == 'project'){
			ItemShare = ShareItemFactory.Project;
		}
	}

	function _init(itemType, params) {
		_setType(itemType);
		currStep = 0;
		$scope.items = [];
		$scope.callback = params && params.callback;
		$scope.folderId = "";
		$scope.inviteMode = false;
		$scope.selected = {};
		$scope.categories = [];
		$scope.shareTarget = {
			_id: 'jibao'
		};
	}
	
	function _getUserOrgs() {
		UserFactory.listOrg().then(function (resp) {
			if(resp.status === 200){
				resp = resp.data;
				if(resp.success){
					$scope.targetOrgs = resp.data || [];
				}
			}
		});
	}

	function close() {
		$ngModal.closeModal(modalName);
		$scope.callback();
	}
	
	function clearSelected() {
		$scope.items.forEach(function(course) {
			course.isSelected = false;
		});
		$scope.selected = {};
	}

	function labelTags() {
		$scope.thumbnail = $scope.selected.thumbnail;
		
		$scope.categories.forEach(function(category, j) {
			$scope.categories[j].checked = false;

			if ($scope.selected.categories && $scope.selected.categories.length > 0) {
				$scope.selected.categories.forEach(function(isCheckedCourse, i) {
					if (isCheckedCourse._id == category._id) {
						$scope.categories[j].checked = true;
					}
				});
			}
		});
	}
	
	function getCheckedCategoryIds() {
		var checkeds = $scope.categories.filter(function(category) {
			if (category.checked) {
				return true;
			}
			return false;
		});
		var categoryIds = checkeds.map(function(c) {
			return c._id;
		});
		return categoryIds;
	}
	
	function update(callback) {
		var categoryIds = getCheckedCategoryIds().join();

		var tags = [];
		if ($scope.selected.tags && $scope.selected.tags.length > 0)
			tags = $scope.selected.tags.map(function(tag) {
				return tag.text;
			});
		
		var newItem = {
			id: $scope.selected._id,
			name: $scope.selected.name,
			description: $scope.selected.description,
			categories: categoryIds,
			tags: tags,
			thumbnail: $scope.selected.thumbnail,
			isPublic: $scope.selected.isPublic,
			backgroundColor: $scope.selected.backgroundColor
		};
		
		//防呆
		if ($scope.selected.ticketItemPublicId){
			newItem.ticketItemPublicId = $scope.selected.ticketItemPublicId;
		}

		ItemShare.updateItem(newItem).then(function(resp) {
			callback(resp);
		});
	}
});
